import { IContentfulLotData, IMojitoCollection } from "@interfaces";

export function generateFakeMojitoLots(
  mojitoItems: IMojitoCollection["items"]
): IContentfulLotData[] {
  return (mojitoItems?.map((e, i) => {
    return {
      lotId: i + 1,
      mojitoId: e.id,
      sys: {
        id: "string",
        publishedAt: "string",
      },
      title: e.name,
      subtitle: "Subtitle",
      imagesCollection: {
        items: [
          {
            url: `/images/template/${
              e.name.toLowerCase().includes("mojito")
                ? "placeholder_mojito"
                : "placeholder"
            }.jpg`,
            title: "title",
            contentType: "image/jpeg",
          },
        ],
      },
      author: {
        name: "author",
        about: "string",
        avatar: {
          url: "string",
          title: "string",
        },
        slug: "string",
      },
      createdAt: "2021-06-28T00:00:00.000Z",
      estimatePrice: "string",
      purchasedAt: "string",
      smartContractAddress: "string",
      tokenId: "string",
      // collector: IContentfulCollector,
      aboutLot:
        "Praesent a diam sollicitudin, cursus ex eget, hendrerit quam. Integer non lacus et nulla efficitur condimentum. Aliquam mattis erat porta leo vulputate, vitae laoreet neque lacinia. Nunc tristique tortor vitae maximus porta. Integer viverra, lacus tempus varius volutpat, ipsum nulla ultrices elit, eu ornare ipsum arcu in nisi. Maecenas sodales eros dolor, ut pretium turpis maximus quis. Cras metus eros, laoreet porta auctor sed, faucibus vel orci. Nullam in rhoncus sapien. Sed mattis semper sapien. Etiam urna justo, gravida vel diam non, consequat auctor diam. Maecenas eu ipsum scelerisque, volutpat nisi eu, molestie sem. Sed nunc ante, tristique volutpat urna vulputate, suscipit egestas mi. Nulla vel ligula in enim efficitur tempor. Aenean condimentum dapibus eleifend. Nunc volutpat odio dolor, in elementum dui varius at. Vestibulum at metus a lacus lacinia ullamcorper.",
      note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae accumsan enim. Maecenas at risus at diam mattis aliquam ut et ligula. ",
      history: null,
      video: "string",
      conditionReportText: "string",
      shortCollectorDescription: "string",
      slug: e.slug,
      nftLink: "string",
      nftVideoIds: ["string"],
      lotPreviewBackgroundColor: "string",
    };
  }) ?? []) as unknown as IContentfulLotData[];
}
