import axios from "axios";
import { config } from "@constants";

const spaceId = config.CONTENTFUL_SM_SPACES_ID;
const accessToken = config.CONTENTFUL_SM_AUTH_TOKEN;
const environment = config.CONTENTFUL_ENVIRONMENT;

//get field entry contentful
export const FieldRecursion = async (id: string): Promise<false | any> => {
  if (id) {
    const promise: any = await axios.get(
      "https://cdn.contentful.com/spaces/" +
        spaceId +
        "/environments/" +
        environment +
        "/entries/" +
        id +
        "?access_token=" +
        accessToken +
        " "
    );
    return promise;
  }
  return false;
};

export const FetchEntriesByContentType = async (
  contentTypeId: string
): Promise<false | any> => {
  if (!contentTypeId) {
    return false;
  }

  const url = `https://cdn.contentful.com/spaces/${spaceId}/environments/${environment}/entries?access_token=${accessToken}&content_type=${contentTypeId}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching content type:", error);
    return false;
  }
};

//get image or video entry contentful
export const assetChecking = async (
  id: string
): Promise<false | Promise<any>> => {
  if (id) {
    const asset: any = await axios.get(
      "https://cdn.contentful.com/spaces/" +
        spaceId +
        "/environments/" +
        environment +
        "/assets/" +
        id +
        "?access_token=" +
        accessToken +
        "  "
    );

    return asset;
  }
  return false;
};

interface homeProps {
  id: string | undefined;
  runTimeHome: (e: any) => any;
}

export const HomeRender = async (props: homeProps): Promise<any> => {
  const arraySave: any = [];
  const getHome = await FieldRecursion(props.id ?? "");

  //get run contentful entry
  for (let i = 0; i < getHome?.data?.fields?.sections.length; i++) {
    const homeData = await FieldRecursion(
      getHome?.data?.fields?.sections[i]?.sys?.id
    );
    const data = homeData?.data?.fields;
    const typeData = homeData?.data?.sys?.contentType?.sys?.id;

    if (typeData == "bannerPage") {
      //based on bannerpage get here

      const imageArray = [];

      for (let j = 0; j < data?.lot?.length; j++) {
        const homeDataInner = await FieldRecursion(data?.lot[j]?.sys?.id);
        const dataHome = homeDataInner?.data?.fields;
        const imageField = await assetChecking(dataHome?.image?.sys?.id);

        const tempArr = {
          url: imageField?.data?.fields?.file?.url,
          title: dataHome.title,
          description: dataHome?.description,
          buttonTitle: dataHome?.buttonTitle,
          button_redirecting_url: dataHome?.button_redirecting_url,
        };
        imageArray.push(tempArr);
      }
      const getarrSave = {
        getData: imageArray,
        contentTitle: "bannerPage",
      };
      arraySave.push(getarrSave);
    } else if (typeData == "upcommingSales") {
      //based on upcommingSales get here

      const imageArray = [];

      for (let j = 0; j < data?.lot?.length; j++) {
        const UPInner = await FieldRecursion(data?.lot[j]?.sys?.id);
        const dataHome = UPInner?.data?.fields;
        const imageField = await assetChecking(dataHome?.image?.sys?.id);

        const tempArr = {
          url: imageField?.data?.fields?.file?.url,
          title: dataHome.title,
          sub_title: dataHome.sub_title,
          description: dataHome?.description,
          sale_info: dataHome?.sale_info,
          redirectionUrl: dataHome?.redirectionUrl,
        };

        imageArray.push(tempArr);
      }
      const getarrSave = {
        title: data?.title,
        saveData: imageArray,
        contentTitle: "upcommingSales",
      };
      arraySave.push(getarrSave);
    } else if (typeData == "featuredArtist") {
      //based on Artist Spotlight get here

      const imageField = await assetChecking(data?.image?.sys?.id);
      const tempArr = {
        url: imageField?.data?.fields?.file?.url,
        title: data.title,
        description: data.description,
        button_redirecting_url: data?.button_redirecting_url,
        contentTitle: "featuredArtist",
      };
      arraySave.push(tempArr);
    } else if (typeData == "video") {
      //based on Video get here

      const VideoData = await assetChecking(data?.video?.sys?.id);
      const tempArr = {
        url: VideoData?.data?.fields?.file?.url,
        title: data?.title,
        text: data?.text,
        textCaption: data?.textCaption,
        contentTitle: "video",
        page: "home",
      };
      arraySave.push(tempArr);
    }
  }
  if (getHome?.data?.fields?.featuredItem) {
    //based on highlight get here

    const tempArr = {
      mojito_category_id: getHome?.data?.fields?.featuredItem,
      contentTitle: "highlighted_Nfts",
    };
    arraySave.push(tempArr);
  }
  props.runTimeHome(arraySave);

  return arraySave;
};

interface ArtistPageProps {
  id: string;
  landingPage: (e: any) => any;
  sectionPage: (e: any) => any;
  bannerOverview: (e: any) => any;
  bannerArtwork: (e: any) => any;
  bannerCollection: (e: any) => any;
  setartworkid: any;
  setmultipleids: any;
  setcollectionid: any;
  setshowmintdata: any;
}

export const ArtistPageApi = async (props: ArtistPageProps): Promise<void> => {
  let artworkIDNFT = false;
  const arraySave: any = [];

  //first entry id overview and artwork check
  const getArtist = await FieldRecursion(props.id);

  //how many section get
  const SectionArray = {
    Overview_page_required: getArtist?.data?.fields?.Overview_page_required,
    OnetoOneReq: getArtist?.data?.fields?.one_of_one_page_required,
    collecectionReq: getArtist?.data?.fields?.collection_page_required,
  };
  props.sectionPage(SectionArray);

  for (let m = 0; m < getArtist?.data?.fields?.sections?.length; m++) {
    const aboutUsData = await FieldRecursion(
      getArtist?.data?.fields?.sections[m]?.sys?.id
    );

    if (!artworkIDNFT && aboutUsData?.data?.fields?.mojitoArtworkReferenceId) {
      props.setartworkid(aboutUsData?.data?.fields?.mojitoArtworkReferenceId);
      artworkIDNFT = true;
    }

    if (aboutUsData?.data?.fields?.showMintData) {
      props.setshowmintdata(aboutUsData?.data?.fields?.showMintData);
    }

    if (aboutUsData?.data?.fields?.mojitoArtworkReferenceIds) {
      props.setmultipleids(
        aboutUsData?.data?.fields?.mojitoArtworkReferenceIds
      );
      artworkIDNFT = true;
    }

    for (let i = 0; i < aboutUsData?.data?.fields?.lot?.length; i++) {
      const getData = await FieldRecursion(
        aboutUsData?.data?.fields?.lot[i]?.sys?.id
      );
      const data = getData?.data?.fields;
      const typeData = aboutUsData?.data?.sys?.contentType?.sys?.id;
      const typeContent = getData?.data?.sys?.contentType?.sys?.id;
      if (typeContent == "homeBanner") {
        //search overview banner or artwork banner or collection banner
        const imageField = await assetChecking(data?.image?.sys?.id);
        if (typeData == "Artist_Landing_Page") {
          const tempArr = {
            url: imageField?.data?.fields?.file?.url,
            title: data.title,
            description: data?.description,
            button_redirecting_url: data?.button_redirecting_url,
          };
          props.bannerOverview(tempArr);
        } else if (typeData == "Artist_1to1") {
          const tempArr = {
            url: imageField?.data?.fields?.file?.url,
            title: data.title,
            description: data?.description,
            button_redirecting_url: data?.button_redirecting_url,
          };
          props.bannerArtwork(tempArr);
        } else if (typeData == "artistCollection") {
          const tempArr = {
            url: imageField?.data?.fields?.file?.url,
            title: data.title,
            description: data?.description,
            button_redirecting_url: data?.button_redirecting_url,
          };
          props.bannerCollection(tempArr);
        }
      } else if (
        typeData == "artistCollection" &&
        typeContent == "Mojito_catogery_collections"
      ) {
        //get collection tab id
        const tempArr = {
          mojito_category_collection_name:
            data?.mojito_category_collection_name,
          mojito_category_reference_id: data?.mojito_category_reference_id,
        };
        props.setcollectionid(tempArr);
      } else if (typeData == "Artist_Landing_Page" && typeContent == "video") {
        //get Video
        const VideoData = await assetChecking(data?.video?.sys?.id);
        const tempArr = {
          url: VideoData?.data?.fields?.file?.url,
          title: data?.title,
          text: data?.text,
          textCaption: data?.textCaption,
          contentTitle: "video",
          page: "artist",
        };
        arraySave.push(tempArr);
      } else if (
        typeData == "Artist_Landing_Page" &&
        typeContent == "ARTIST_CONTENT"
      ) {
        //get ARTIST_CONTENT
        const tempArr = {
          title: data?.title,
          content1: data?.content1,
          content2: data?.content2,
          testimonial_mssage: data?.testimonial_mssage,
          testimonial_given_by: data?.testimonial_given_by,
          contentTitle: "ARTIST_CONTENT",
        };
        arraySave.push(tempArr);
      } else if (
        typeData == "Artist_Landing_Page" &&
        typeContent == "twoColumnContent"
      ) {
        //get image content

        const imageField = await assetChecking(data?.image?.sys?.id);
        const tempArr = {
          url: imageField?.data?.fields?.file?.url,
          title: data.title,
          text: data?.text,
          imageCaption: data?.imageCaption,
          imageCaptionAuthor: data?.imageCaptionAuthor,
          redirectionLink: data?.redirectionLink,
          objectFit: data?.objectFit,
          contentPadding: data?.contentPadding,
          contentTitle: "twoColumnContent",
        };
        arraySave.push(tempArr);
      } else if (
        typeData == "Artist_Landing_Page" &&
        typeContent == "aboutTheArtist"
      ) {
        //get About The Artist

        const imageField = await assetChecking(data?.image?.sys?.id);
        const tempArr = {
          url: imageField?.data?.fields?.file?.url,
          title: data.title,
          text: data?.text,
          imageCaption: data?.imageCaption,
          imageCaptionAuthor: data?.imageCaptionAuthor,
          contentPadding: data?.contentPadding,
          objectFit: data?.objectFit,
          contentTitle: "twoColumnContent",
        };
        arraySave.push(tempArr);
      } else if (
        typeData == "Artist_Landing_Page" &&
        typeContent == "paragraph"
      ) {
        //get paragraph

        const tempArr = {
          title: data.title,
          text: data?.text,
          contentTitle: "paragraph",
        };
        arraySave.push(tempArr);
      } else if (
        typeData == "Artist_Landing_Page" &&
        typeContent == "quoteBlock"
      ) {
        //get quote Block

        const tempArr = {
          quoteAuthor: data?.quoteAuthor,
          quote: data?.quote,
          contentTitle: "quoteBlock",
        };
        arraySave.push(tempArr);
      } else if (
        typeData == "Artist_Landing_Page" &&
        typeContent == "highlighted_Nfts"
      ) {
        //get related

        const tempArr = {
          title: data?.title,
          mojitoArtistId: data?.mojitoArtistId,
          contentTitle: "highlighted_Nfts",
        };
        arraySave.push(tempArr);
      } else if (
        typeData == "Artist_Landing_Page" &&
        typeContent == "sketches"
      ) {
        const sketchDataOne = await FieldRecursion(data?.lot[0]?.sys?.id);
        const sketchDataTwo = await FieldRecursion(data?.lot[1]?.sys?.id);
        const sketchDatainsideOne: any = [];
        const sketchDatainsideTwo: any = [];
        for (let m = 0; m < sketchDataOne?.data?.fields?.lot?.length; m++) {
          const getSketchOne = await FieldRecursion(
            sketchDataOne?.data?.fields?.lot[m]?.sys?.id
          );
          const dataSketch = getSketchOne?.data?.fields;
          const imageField = await assetChecking(
            getSketchOne?.data?.fields?.image?.sys?.id
          );

          const tempArray = {
            title: sketchDataOne?.data?.fields?.title,
            url: imageField?.data?.fields?.file?.url,
            imageCaption: dataSketch?.imageCaption,
            imageCaptionAuthor: dataSketch?.imageCaptionAuthor,
            redirectionLink: dataSketch?.redirectionLink,
          };
          sketchDatainsideOne.push(tempArray);
        }
        for (let m = 0; m < sketchDataTwo?.data?.fields?.lot?.length; m++) {
          const getSketchOne = await FieldRecursion(
            sketchDataTwo?.data?.fields?.lot[m]?.sys?.id
          );
          const dataSketch = getSketchOne?.data?.fields;
          const imageField = await assetChecking(
            getSketchOne?.data?.fields?.image?.sys?.id
          );

          const tempArray = {
            title: sketchDatainsideTwo?.data?.fields?.title,
            url: imageField?.data?.fields?.file?.url,
            imageCaption: dataSketch?.imageCaption,
            imageCaptionAuthor: dataSketch?.imageCaptionAuthor,
            redirectionLink: dataSketch?.redirectionLink,
          };
          sketchDatainsideTwo.push(tempArray);
        }
        const sendSketch = {
          sketchDatainsideOne: sketchDatainsideOne,
          sketchDatainsideTwo: sketchDatainsideTwo,
          contentTitle: "sketches",
          titleSketchOne: sketchDataOne?.data?.fields?.title,
          titleSketchTwo: sketchDataTwo?.data?.fields?.title,
        };
        arraySave.push(sendSketch);
      } else if (
        typeData == "Artist_Landing_Page" &&
        typeContent == "characteristics"
      ) {
        // const sketchDataOne = await FieldRecursion(data?.lot?.sys?.id);
        const sketchDatainsideOne: any = [];
        for (let m = 0; m < data?.lot?.length; m++) {
          const getSketchOne = await FieldRecursion(data?.lot[m]?.sys?.id);
          const dataSketch = getSketchOne?.data?.fields;
          const imageField = await assetChecking(
            getSketchOne?.data?.fields?.image?.sys?.id
          );

          const tempArray = {
            url: imageField?.data?.fields?.file?.url,
            imageCaption: dataSketch?.imageCaption,
            imageCaptionAuthor: dataSketch?.imageCaptionAuthor,
            redirectionLink: dataSketch?.redirectionLink,
          };
          sketchDatainsideOne.push(tempArray);
        }
        const sendSketch = {
          title: data?.title,
          characteristics: sketchDatainsideOne,
          contentTitle: "characteristics",
        };
        arraySave.push(sendSketch);
      }

      props.landingPage(arraySave);
    }
  }
};
