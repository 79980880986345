import { useRouter } from "next/router";
import { IContentfulAuctionsQuery, IContentAdditionalSales } from "@interfaces";
import { useContentful } from "@hooks/useContentful";
import { EContentfulQueries, IUseQueryResult } from "@state";

export function useAuction(auctionSlug: string): {
  slugList: string[];
  slugListLoading: boolean;
  slugListError: IUseQueryResult["error"];
  contentfulData: any
} {
  const { data, error, loading } = useContentful<IContentfulAuctionsQuery>(
    EContentfulQueries.auctionDetailsBySlug,
    { slug: auctionSlug }
  );

  return {
    contentfulData: data?.auctionCollection?.items[0],
    slugList:
      data?.auctionCollection?.items[0]?.additionalSalesCollection?.items
        ?.map((collection: IContentAdditionalSales) => collection?.slug)
        .filter((item) => item) ?? [],
    slugListLoading: loading,
    slugListError: error,
  };
}

export function useAuctionLots(auctionSlug: string): {
  slugListLoading: boolean;
  slugListError: IUseQueryResult["error"];
  lotsData: any
} {
  const { data, error, loading } = useContentful<IContentfulAuctionsQuery>(
    EContentfulQueries.fullLot,
    { slug: auctionSlug }
  );
  return {
    lotsData: data,
    slugListLoading: loading,
    slugListError: error,
  };
}

export function useContentfulAdditionalSalesBySlug(slugs: string[]): {
  salesBySlug: IContentAdditionalSales[];
  salesBySlugLoading: boolean;
  salesBySlugError: IUseQueryResult["error"];
} {
  const { data, error, loading } = useContentful<any>(
    EContentfulQueries.additionalSalesBySlug,
    { slug: Array.isArray(slugs) ? slugs : [slugs] }
  );

  return {
    salesBySlug: data?.offLineAdditionalSaleCollection?.items ?? [],
    salesBySlugLoading: loading,
    salesBySlugError: error,
  };
}

export type UseAdditionalSaleHook = {
  salesBySlug: IContentAdditionalSales[];
};

