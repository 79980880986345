export const environment = {
  CONTENTFUL_RESOURCE_ABOUT_US_ID: process.env.NEXT_PUBLIC_CONTENTFUL_RESOURCE_ABOUT_US_ID,
  CONTENTFUL_SM_ARTIST_ENTRY_ID: process.env.NEXT_PUBLIC_CONTENTFUL_SM_ARTIST_ENTRY_ID,
  CONTENTFUL_SM_HOME_ENTRY_ID: process.env.NEXT_PUBLIC_CONTENTFUL_SM_HOME_ENTRY_ID,
  CONTENTFUL_SM_AUTH_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_SM_AUTH_TOKEN,
  CONTENTFUL_SM_SPACES_ID: process.env.NEXT_PUBLIC_CONTENTFUL_SM_SPACES_ID,
  CONTENTFUL_ENVIRONMENT: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_VIEW_ALL_ID: process.env.NEXT_PUBLIC_CONTENTFUL_VIEW_ALL_ID,
  CONTENTFUL_RESOURCE_TOKEN_ID: process.env.NEXT_PUBLIC_CONTENTFUL_RESOURCE_TOKEN_ID,
}

export const config = {
  ENVIRONMENT: process.env.ENVIRONMENT || "prod",
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  AUTH0_CLIENTID: process.env.NEXT_PUBLIC_AUTH0_CLIENTID,
  AUTH0_REDIRECT_URI:
    process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI ||
    (typeof document !== "undefined" && document.location.origin) ||
    undefined,
  CONTENTFUL_URL: process.env.NEXT_PUBLIC_CONTENTFUL_URL || "",
  CONTENTFUL_AUTH_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_AUTH_TOKEN,
  MOJITO_API_URL: process.env.NEXT_PUBLIC_MOJITO_API_URL || "",
  INVOICE_URL: process.env.NEXT_PUBLIC_INVOICE_URL || "",
  SYNC_REGISTRATION_URL: process.env.NEXT_PUBLIC_SYNC_REGISTRATION_URL || "",
  CUSTOMER_AUDIENCE: process.env.NEXT_PUBLIC_CUSTOMER_AUDIENCE,
  CUSTOMER_SCOPE: process.env.NEXT_PUBLIC_CUSTOMER_SCOPE,
  SOTHEBYS_DOMAIN:
    process.env.NEXT_PUBLIC_SOTHEBYS_DOMAIN || "https://staging.sothebys.com",
  SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY || "",
  WALLETCONNECT_PROJECT_ID: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID || "",
  ORGANIZATION_ID:
    process.env.NEXT_PUBLIC_API_ORGANIZATION_ID ||
    "8fb128bd-f55d-4bcc-8b6c-0beb684e4d4e",
  MARKETPLACE_ID:
    process.env.NEXT_PUBLIC_MARKETPLACE_ID ||
    "c5ac4f84-b78b-4cb7-a24f-7f0c38da0eb2",
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN || "",
  SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV || "dev",
  SENTRY_AUTH_TOKEN: process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN || "",
  APP_METADATA_URL: process.env.NEXT_PUBLIC_APP_METADATA_URL || "",
  CLOUDINARY: process.env.NEXT_PUBLIC_CLOUDINARY || "",
  RPC_URL: {
    1: "https://eth-mainnet.g.alchemy.com/v2/iB_9SpJ1yj7MtksRPR6aq7cmxzwAwaL-",
    5: "https://eth-goerli.g.alchemy.com/v2/iB_9SpJ1yj7MtksRPR6aq7cmxzwAwaL-",
    80001: "https://polygon-mumbai.g.alchemy.com/v2/iB_9SpJ1yj7MtksRPR6aq7cmxzwAwaL-",
    137: "https://polygon-mainnet.g.alchemy.com/v2/iB_9SpJ1yj7MtksRPR6aq7cmxzwAwaL-",
    11155111: "https://eth-sepolia.g.alchemy.com/v2/Oa9bs3bK2FdZnFNrErrEmkF0fw1djNTJ"
  },
  CONTENTFUL_RESOURCE_ABOUT_US_ID: environment.CONTENTFUL_RESOURCE_ABOUT_US_ID || "",
  CONTENTFUL_SM_ARTIST_ENTRY_ID: environment.CONTENTFUL_SM_ARTIST_ENTRY_ID || "",
  CONTENTFUL_SM_HOME_ENTRY_ID: environment.CONTENTFUL_SM_HOME_ENTRY_ID || "",
  CONTENTFUL_SM_AUTH_TOKEN: environment.CONTENTFUL_SM_AUTH_TOKEN || "",
  CONTENTFUL_SM_SPACES_ID: environment.CONTENTFUL_SM_SPACES_ID || "",
  CONTENTFUL_ENVIRONMENT: environment.CONTENTFUL_ENVIRONMENT || "",
  CONTENTFUL_VIEW_ALL: environment.CONTENTFUL_VIEW_ALL_ID || "",
  CONTENTFUL_RESOURCE_TOKEN_ID: environment.CONTENTFUL_RESOURCE_TOKEN_ID || "",
  SEGMENT_ANALYTICS_ENABLE: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_ENABLE || true,
  SECONDARY_MARKETPLACE_ID: process.env.NEXT_PUBLIC_MARKETPLACE_ID_SECONDARY || "",
  DYNAMIC_ENVIRONMENT_ID: process.env.NEXT_PUBLIC_DYNAMIC_ENVIRONMENT_ID || ""
};


