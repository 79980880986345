import { CSSProperties, FC, memo } from "react";
import styled from "styled-components";
import Link from "next/link";
import { formatDateToUS } from "@utils";
import { Image } from "../../image";
import { Grid } from "@material-ui/core";

interface ICollectedBy {
  collectorName: string;
  collectorImage?: string;
  collectorSlug?: string;
  purchaseDate?: string;
  style?: CSSProperties;
  alignRight?: boolean;
  uppercase?: boolean;
}

const CollectedByText = styled((props) => <Grid {...props} />)<{
  $alignRight: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  justify-content: ${({ $alignRight }) =>
    $alignRight ? "flex-end" : "flex-start"};

  a {
    text-decoration: underline;
  }
`;

const LotImage: FC<{
  src: string;
  alt: string;
  quality?: number;
  size?: number;
}> = ({
  //todo ekuznetski refactor, move to one reusable component in general.tsx
  src,
  alt,
  quality,
  size,
}) => {
  return (
    <Image
      src={src}
      alt={alt}
      height={size ? size : 91}
      width={size ? size : 91}
      objectFit={"cover"}
      quality={quality ? quality : 75}
    />
  );
};

const ImageMemo = memo(LotImage);

const ImageWrapper = styled.div<{ size?: number }>`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  width: ${({ size }) => (size ? size + "px" : "91px")};
  height: ${({ size }) => (size ? size + "px" : "91px")};
  margin-right: 10px;
`;

export const CollectedBy: FC<ICollectedBy> = ({
  // todo accept ICollector instead collectorName purchaseDate collectorImage collectorSlug,
  collectorName,
  purchaseDate,
  collectorImage,
  collectorSlug,
  alignRight = false,
  uppercase = false,
}) => {
  return collectorName ? (
    <>
      {collectorImage && (
        <ImageWrapper size={44}>
          <ImageMemo
            src={collectorImage}
            alt={collectorName}
            size={44}
            quality={75}
          />
        </ImageWrapper>
      )}
      <CollectedByText
        $alignRight={alignRight}
        style={{ textTransform: uppercase ? "uppercase" : "none" }}>
        <span style={{ marginRight: "4px" }}>Collected by </span>
        <Link href={`/collectors/${collectorSlug}`} passHref>
          <a>{collectorName}</a>
        </Link>
        {purchaseDate ? `, ${formatDateToUS(purchaseDate)}` : ""}
      </CollectedByText>
    </>
  ) : null;
};
