import { contentfulGqlClient } from '@hooks/useContentful';
import { contentfulQueries, EContentfulQueries } from "@state";
import { dehydrate, DehydratedState } from "react-query";
import { contentfulNormalizer } from "./gqlDataNormalizer.util";
import { gqlRequest, queryClient } from "./gqlRequest.util";

export async function getAuthors(props: any): Promise<{
  dehydratedState: DehydratedState;
}> {
  const pathArr = (props.asPath ?? props?.resolvedUrl ?? props.req?.url)
    ?.split("#")[0]
    .split("?")[0]
    .split("/");
  const auctionPageSlug = pathArr && pathArr[1];

  if (auctionPageSlug == "500")
    return { dehydratedState: dehydrate(queryClient) };

  await queryClient.prefetchQuery(
    [`Contentful ${EContentfulQueries[EContentfulQueries.authors]}`],
    gqlRequest.bind(null, {
      query: contentfulQueries[EContentfulQueries.authors],
      normalizerFn: contentfulNormalizer,
      gqlClient: contentfulGqlClient,
    })
  );

  props.res?.setHeader(
    "Cache-Control",
    "public, s-maxage=10, stale-while-revalidate=59"
  );
  return { dehydratedState: dehydrate(queryClient) };
}

export default getAuthors;
