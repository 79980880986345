import {
  IMojitoCollectionItemAuctionLot,
  IMojitoCollectionItemBuyNowLot,
} from "@interfaces";

export function isCollectionItemAuctionLot(
  lot: IMojitoCollectionItemAuctionLot | IMojitoCollectionItemBuyNowLot
): lot is IMojitoCollectionItemAuctionLot {
  // eslint-disable-next-line no-prototype-builtins
  return (lot as IMojitoCollectionItemAuctionLot).hasOwnProperty("currentBid");
}

export function isCollectionItemBuyNowLot(
  lot: IMojitoCollectionItemAuctionLot | IMojitoCollectionItemBuyNowLot
): lot is IMojitoCollectionItemBuyNowLot {
  // eslint-disable-next-line no-prototype-builtins
  return (lot as IMojitoCollectionItemBuyNowLot).hasOwnProperty("unitPrice");
}

export function checkIs3DSPage(pathname: string): boolean {
  return ["/payments/success", "/payments/error", "/payments/failure", "/sales", "/salgado", "/aegirls"].includes(pathname);
}
