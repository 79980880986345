import { EViewSize } from "@enums";
import { useCollection } from "@hooks";
import { Box, Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { formatDateToUS } from "@utils";
import { memo, useContext } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { Image } from "../../image";
import { Markdown } from "../../markdown";
import { FavoriteButton } from "../../saveToFavoriteButton";
import { Scrollbar } from "../../scrollbar";
import { CollectionItemContext } from "../state/auctionItemContext";
import { CardHeader } from "./cardHeader";
import { CollectedBy } from "./collectedBy";
import { Estimate, ItemTitle, LotWrapper, SubsectionTitle } from "./general";
import { HistoryLine } from "./historyLine";

const InfoContainer = styled((props) => <Grid {...props} />)`
  && {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
`;

const CollectorInfo = styled((props) => <Grid {...props} />)`
  && {
    margin: 60px 0 18px 0;

    .CollectorInfoHead {
      display: flex;
      align-items: center;
      padding-bottom: 12px;
    }

    .CollectorInfoDate {
      font-size: 12px;
      margin-left: 10px;
    }

    .CollectorInfoText {
      padding-top: 28px;
      font-size: 32px;
      font-style: italic;
      line-height: 1;
    }

    @media screen and (max-width: ${EViewSize.tablet}) {
      margin: 30px 0 18px 0;
    }
  }
`;

const ImageWrapper = styled.div<{ size?: number }>`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  width: ${({ size }) => (size ? size + "px" : "91px")};
  height: ${({ size }) => (size ? size + "px" : "91px")};
  margin-right: 25px;
  position: relative;

  @media screen and (max-width: ${EViewSize.tablet}) {
    margin-right: 20px;
  }

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-bottom: 10px;
  }
`;

const ConditionReport = styled(Accordion)`
  &&& {
    margin-top: 60px;
    background: none;
    border: none;

    .ExpandIcon {
      fill: #0a0a0a;
    }

    &.Mui-expanded {
      .ExpandIcon {
        transform: rotate(45deg);
      }
    }

    .ConditionReportTitle {
      font-weight: 600;
      font-size: 10px;
    }
  }
`;

const CentralGrid = styled((props) => <Grid {...props} />)`
  && {
    margin: auto 0;
  }
`;

const ItemCreated = styled.div`
  margin-bottom: 10px;
  font-style: italic;
  font-size: 18px;
  line-height: 22px;
`;

const LotInfoCardMemo = styled(
  memo(({ lotData }: any) => {
    const { collection } = useCollection();
    const collectionItemId = collection?.items?.find(
      (e) => e.id === lotData?.mojitoId
    )?.id;
    const reportHasText: boolean = lotData?.conditionReportText?.length > 0;

    return lotData ? (
      <LotWrapper>
        <Scrollbar>
          <CardHeader />
          <InfoContainer container spacing={0}>
            <Grid item sm={7} md={10}>
              <Grid container flexWrap={"nowrap"}>
                <Grid item>
                  {lotData?.author?.avatar?.url && (
                    <ImageWrapper>
                      <Image
                        src={lotData.author.avatar.url}
                        alt={lotData.author.name}
                        layout="fill"
                        showLoader={false}
                      />
                    </ImageWrapper>
                  )}
                </Grid>
                <CentralGrid item>
                  <ItemTitle style={{ marginBottom: "10px" }}>
                    {lotData.author?.name}
                  </ItemTitle>
                  <ItemCreated>
                    {lotData?.title},{" "}
                    {formatDateToUS(lotData?.createdAt, "YYYY")}
                  </ItemCreated>
                  <Estimate lotData={lotData} />
                </CentralGrid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems={"flex-end"}>
                <FavoriteButton collectionItemId={collectionItemId} />
              </Box>
            </Grid>
            {lotData.collector && (
              <CollectorInfo item xs={12}>
                <Box className="CollectorInfoHead">
                  <CollectedBy
                    collectorImage={lotData.collector.avatar.url}
                    collectorName={lotData.collector.name}
                    collectorSlug={lotData.collector.slug}
                    purchaseDate={lotData.purchasedAt}
                  />
                </Box>
                {lotData.shortCollectorDescription && (
                  <Markdown
                    className="CollectorInfoText"
                    text={lotData.shortCollectorDescription}
                  />
                )}
              </CollectorInfo>
            )}
            <Grid container spacing={2}>
              {lotData.aboutLot && (
                <Grid item sm={12} md={6}>
                  <SubsectionTitle>ABOUT THIS LOT</SubsectionTitle>
                  <Markdown text={lotData.aboutLot} />
                </Grid>
              )}
              {lotData?.author?.about && (
                <Grid item sm={12} md={6}>
                  <SubsectionTitle>ABOUT THE ARTIST</SubsectionTitle>
                  <Markdown text={lotData.author.about} />
                </Grid>
              )}
            </Grid>
          </InfoContainer>
          <HistoryLine />
          {reportHasText && (
            <ConditionReport
              elevation={0}
              sx={{
                "&:before": {
                  display: "none",
                },
              }}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="ConditionReportTitle"
                expandIcon={
                  <SVG
                    src="/icons/plus.svg"
                    width="15px"
                    className="ExpandIcon"
                  />
                }>
                CONDITION REPORT
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {lotData.conditionReportText && (
                    <Grid item xs={12}>
                      <Markdown text={lotData.conditionReportText} />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </ConditionReport>
          )}
        </Scrollbar>
      </LotWrapper>
    ) : null;
  })
)``;

export const LotInfoCard = styled(() => {
  const { item } = useContext(CollectionItemContext);
  const lotData = item.contentfulData;

  return <LotInfoCardMemo lotData={lotData} />;
})``;
