/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'
import data6 from 'ajv/lib/refs/json-schema-draft-06.json'
import data4 from 'ajv/lib/refs/json-schema-draft-04.json'

export interface AuctionRegistrationCompleted {
	/**
	 * Origin of the auction registration; passes \"auction details\" if registration related event occurred via the auction landing page
	 */
	auction_registration_origin: string
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * For Zaius, \"live_online\" when user registers for online bidding, \"telephone_bid\" with telephone registration, or \"sale_room_bid\" with paddle registration. 
Also used for Live sale bidding method selected during registration
	 */
	bid_method?: BidMethod
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
	 */
	enrollment_status?: string | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * If user is Preferred
	 */
	preferred_member_flag?: boolean | null
	/**
	 * List of product_id values
	 */
	product_id_list?: string[]
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source?: string | null
	/**
	 * Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
	 */
	restricted_enrollment_period?: boolean | null
	/**
	 * Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
	 */
	restricted_sale?: boolean | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
}
export interface BidPlaced {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Maximum bid amount
	 */
	bid_amount: number
	/**
	 * Currency of bid placed
	 */
	bid_currency: string
	/**
	 * For Zaius, \"live_online\" when user registers for online bidding, \"telephone_bid\" with telephone registration, or \"sale_room_bid\" with paddle registration. 
Also used for Live sale bidding method selected during registration
	 */
	bid_method: BidMethod
	/**
	 * Result of the bid placed
	 */
	bid_result: BidResult
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * Low estimate of the product being interacted with
	 */
	low_estimate: number
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid: string
}
export interface BidStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Maximum bid amount
	 */
	bid_amount: number
	/**
	 * Currency of bid placed
	 */
	bid_currency: string
	/**
	 * For Zaius, \"live_online\" when user registers for online bidding, \"telephone_bid\" with telephone registration, or \"sale_room_bid\" with paddle registration. 
Also used for Live sale bidding method selected during registration
	 */
	bid_method: BidMethod
	/**
	 * Result of the bid placed
	 */
	bid_result: BidResult
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid: string
}
export interface CheckoutError {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * ID of the relevant cart. Sometimes referred to as bag_id.
	 */
	cart_id: string
	/**
	 * Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased
	 */
	checkout_type: CheckoutType
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal error code of the error
	 */
	error_code?: string | null
	/**
	 * The description of the error that is received, e.g. \"bad postal code\"
	 */
	error_description: string
	/**
	 * The error message that the users sees on the front-end
	 */
	error_msg: string
	/**
	 * Error type
	 */
	error_type: ErrorType
	/**
	 * The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.
	 */
	payment_method: PaymentMethod
	/**
	 * Revenue associated with the transaction (excluding shipping and tax)
	 */
	revenue: number
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * Represents the shipping the method chosen (only pass this value if a shipping method is set)
	 */
	shipping_method?: string | null
}
export interface CheckoutStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * ID of the relevant cart. Sometimes referred to as bag_id.
	 */
	cart_id: string
	/**
	 * Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased
	 */
	checkout_type: CheckoutType
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * Revenue associated with the transaction (excluding shipping and tax)
	 */
	revenue: number
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
}
export interface ProductsItem {
	/**
	 * Top level product category being viewed
	 */
	category?: string | null
	/**
	 * Second level product category being viewed
	 */
	category_2?: string | null
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The general period of the object
	 */
	period_general: string
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price?: number | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid: string
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name?: string | null
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string | null
}
export interface CheckoutStepCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * ID of the relevant cart. Sometimes referred to as bag_id.
	 */
	cart_id: string
	/**
	 * Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased
	 */
	checkout_type: CheckoutType
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * number of items selected
	 */
	num_products: number
	/**
	 * The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.
	 */
	payment_method?: PaymentMethod
	/**
	 * Properties of the product(s) related to the event
	 */
	products: ProductsItem[]
	/**
	 * Revenue associated with the transaction (excluding shipping and tax)
	 */
	revenue: number
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
	/**
	 * Represents the shipping the method chosen (only pass this value if a shipping method is set)
	 */
	shipping_method: string
	/**
	 * Number representing the number of the a step in a process, e.g. \"1\" if the first step of registration was being completed
	 */
	step: number
	/**
	 * Name of the step that was completed
	 */
	step_name: StepName
}
export interface ErrorOccurred {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * The internal error code of the error
	 */
	error_code: string
	/**
	 * The description of the error that is received, e.g. \"bad postal code\"
	 */
	error_description: string
	/**
	 * The error message that the users sees on the front-end
	 */
	error_msg: string
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name?: string | null
	/**
	 * Type of sale
	 */
	sale_type: SaleType
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
}
export interface ExitLinkClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * URL of external (non sothebys.com) link
	 */
	external_link: string
	/**
	 * If user is Preferred
	 */
	preferred_member_flag?: boolean | null
}
export interface ItemFollowed {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * The type of the item being viewed, followed or otherwise being interacted with
	 */
	item_type?: ItemType
	/**
	 * Browsing language that user has selected via site header
	 */
	language: string
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * Type of sale
	 */
	sale_type?: SaleType
}
export interface ItemSaved {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * The type of the item being viewed, followed or otherwise being interacted with
	 */
	item_type: ItemType
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * Type of sale
	 */
	sale_type: SaleType
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid: string
}
export interface ItemUnfollowed {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	/**
	 * The type of the item being viewed, followed or otherwise being interacted with
	 */
	item_type?: ItemType1
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id?: string | null
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * Type of sale
	 */
	sale_type?: SaleType
}
export interface ItemUnsaved {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * The type of the item being viewed, followed or otherwise being interacted with
	 */
	item_type: ItemType
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * Type of sale
	 */
	sale_type: SaleType
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid: string
}
export interface ListItemCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category: string
	/**
	 * The internal code of the relevant department
	 */
	department_code: DepartmentCode
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type: string
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * The type of the relevant token in a metaverse listing or transaction
	 */
	token_type: string
}
export interface ListItemRemoved {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category: string
	/**
	 * The internal code of the relevant department
	 */
	department_code: DepartmentCode
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type: string
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * The type of the relevant token in a metaverse listing or transaction
	 */
	token_type: string
}
export interface ListItemStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type: string
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * The type of the relevant token in a metaverse listing or transaction
	 */
	token_type: string
}
export interface ListItemStepCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category: string
	/**
	 * The internal code of the relevant department
	 */
	department_code: DepartmentCode
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type: string
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * Number representing the number of the a step in a process, e.g. \"1\" if the first step of registration was being completed
	 */
	step: number
	/**
	 * Name of the step that was completed
	 */
	step_name: StepName
	/**
	 * The type of the relevant token in a metaverse listing or transaction
	 */
	token_type: string
}
export interface LogPageView {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name?: string | null
}
export interface MakeAnOfferClicked {
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Section of the site
	 */
	content_type?: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string | null
	/**
	 * Currency code associated with the relevant product
	 */
	currency?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * Image URL of the product image
	 */
	image_url: string
	/**
	 * true if logged in
	 */
	logged_in?: boolean | null
	/**
	 * name of the product
	 */
	name?: string | null
	/**
	 * If user is Preferred
	 */
	preferred_member_flag?: boolean | null
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price?: number | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id?: string | null
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type?: string | null
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * Sku of the product
	 */
	sku: string
	/**
	 * URL of the product page
	 */
	url?: string | null
}
export interface OfferCanceled {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category: string
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The type of the relevant token in a metaverse listing or transaction
	 */
	token_type: string
}
export interface OfferCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * ID of the relevant cart. Sometimes referred to as bag_id.
	 */
	cart_id?: string | null
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string | null
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * Offer/transaction ID
	 */
	offer_id: any | null
	/**
	 * Numerical number of offer
	 */
	offer_number: string
	/**
	 * Offer amount the user enters
	 */
	offer_price: number
	/**
	 * The percentage difference between list price and offer price
	 */
	percent_of_list_price: number
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type?: string | null
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
	/**
	 * Sku of the product
	 */
	sku: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string | null
}
export interface OfferRejected {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category: string
	/**
	 * The internal code of the relevant department
	 */
	department_code: DepartmentCode
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type: string
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * Quantity of the product
	 */
	quantity: number
}
export interface OfferStarted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type?: string | null
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * Quantity of the product
	 */
	quantity: number
}
export interface OfferStepCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * Offer amount the user enters
	 */
	offer_price: number
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
	 */
	product_type?: string | null
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid?: string | null
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * Number representing the number of the a step in a process, e.g. \"1\" if the first step of registration was being completed
	 */
	step: number
	/**
	 * Name of the step that was completed
	 */
	step_name: StepName
}
export interface ProductsItem1 {
	/**
	 * Top level product category being viewed
	 */
	category?: string | null
	/**
	 * Second level product category being viewed
	 */
	category_2?: string | null
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The general period of the object
	 */
	period_general: string
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price?: number | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
	 */
	product_uuid: string
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * Six-digit SAP sale number
	 */
	sale_id?: string | null
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name?: string | null
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid: string
	/**
	 * The variant of the product (e.g. shoe size or bottle size)
	 */
	variant?: string | null
}
export interface OrderCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * ID of the relevant cart. Sometimes referred to as bag_id.
	 */
	cart_id: string
	/**
	 * Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased
	 */
	checkout_type: CheckoutType
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category: string
	/**
	 * The internal code of the relevant department
	 */
	department_code: DepartmentCode
	/**
	 * The department(s) selling the item(s)
	 */
	departments: string[]
	/**
	 * Amount of fees added to the order
	 */
	fees: number
	/**
	 * number of items selected
	 */
	num_products: number
	/**
	 * The unique id created in the systems for this particular order
	 */
	order_id: any | null
	/**
	 * The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.
	 */
	payment_method: PaymentMethod
	/**
	 * Properties of the product(s) related to the event
	 */
	products: ProductsItem1[]
	/**
	 * Revenue associated with the transaction (excluding shipping and tax)
	 */
	revenue: number
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid?: string | null
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping: number
	/**
	 * Total tax associated with the transaction
	 */
	tax: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total: number
}
export interface SimpleRegistrationStart {
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app?: boolean | null
	/**
	 * Browsing language that user has selected via site header
	 */
	language?: string | null
	/**
	 * Passes 'web' during live sale registration
	 */
	registration_source: string
}
export interface SwapCompleted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Currency code associated with the relevant product
	 */
	currency: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * The name of the product or the relevant item
	 */
	product_name: string
	/**
	 * Revenue associated with the transaction (excluding shipping and tax)
	 */
	revenue: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total: number
}
export interface TermsAccepted {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Section of the site
	 */
	content_type: ContentType
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * The internal code of the relevant department
	 */
	department_code?: DepartmentCode
	/**
	 * States whether the user is browsing the web from within the app or not
	 */
	in_app: boolean
	/**
	 * Six-digit SAP sale number
	 */
	sale_id: string
	/**
	 * The name of the sale (auction or specific buy-now sale)
	 */
	sale_name: string
	/**
	 * The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
	 */
	sale_uuid: string
	/**
	 * Name of the terms that are being accepted, e.g. 'Auction Terms', 'Marketplace Terms', 'LFC Email Signup'
	 */
	terms: Terms
}
export interface TransactionRejected {
	/**
	 * User's Auth0 value
	 */
	auth0_id: string
	/**
	 * Creator associated with the creation of the product
	 */
	creator?: string
	/**
	 * Client system party ID
	 */
	customer_id: number
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category: string
	/**
	 * Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
	 */
	price: number
	/**
	 * For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
	 */
	product_id: string
	/**
	 * Was the transaction or offer rejected by e.g. buyer or seller
	 */
	rejected_by: RejectedBy
	transaction_type: string
}
export interface VideoPlaybackCompleted {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate: string
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Set to language selected in the Closed Caption dropdown
	 */
	closed_captions?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * true if content is a full episode and false otherwise
	 */
	full_episode?: boolean | null
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen?: boolean | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	keywords?: string[]
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean | null
	/**
	 * Playlist display name
	 */
	playlist_display_name?: string | null
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string | null
	/**
	 * The position (integer) of the thing being interacted with
	 */
	position?: number | null
	/**
	 * If user is Preferred
	 */
	preferred_member_flag?: boolean | null
	/**
	 * The quality of the video
	 */
	quality?: string | null
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound: number
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback
	 */
	total_length?: number | null
	/**
	 * Video player
	 */
	video_player?: string | null
	/**
	 * The title of the video
	 */
	video_title?: string | null
}
export interface VideoPlaybackStarted {
	/**
	 * An ISO 8601 Date String representing the original air date or published date
	 */
	airdate?: string | null
	/**
	 * User's Auth0 value
	 */
	auth0_id?: string | null
	/**
	 * Set to language selected in the Closed Caption dropdown
	 */
	closed_captions?: string | null
	/**
	 * Client system party ID
	 */
	customer_id?: number | null
	/**
	 * Name of department(s) to which the event is related
	 */
	department_category?: string | null
	/**
	 * true if playback is currently in full screen mode and false otherwise
	 */
	full_screen?: boolean | null
	/**
	 * An array of arbitrary keywords or tags that describe or categorize the video content
	 */
	keywords?: string[]
	/**
	 * true if content is Sotheby's original and false if licensed from 3rd party
	 */
	original_content?: boolean | null
	/**
	 * Playlist display name
	 */
	playlist_display_name?: string | null
	/**
	 * Internal name of playlist if applicable
	 */
	playlist_internal_name?: string | null
	/**
	 * The position (integer) of the thing being interacted with
	 */
	position?: number | null
	/**
	 * If user is Preferred
	 */
	preferred_member_flag?: boolean | null
	/**
	 * The quality of the video
	 */
	quality?: string | null
	/**
	 * The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
	 */
	sound?: number | null
	/**
	 * The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback
	 */
	total_length?: number | null
	/**
	 * Video player
	 */
	video_player?: string | null
	/**
	 * The title of the video
	 */
	video_title?: string | null
}

export enum BidMethod {
	LiveOnline = 'live_online',
	AdvanceBid = 'advance_bid',
	OnlineOnly = 'online_only',
	AbsenteeBid = 'absentee_bid',
	TelephoneBid = 'telephone_bid',
	SaleRoomBid = 'sale_room_bid',
}
export enum BidResult {
	BidIsWithYou = 'bid is with you',
	BidTooLow = 'bid too low',
}
export enum ContentType {
	BuyNow = 'Buy Now',
	Auctions = 'Auctions',
	PrivateSale = 'Private Sale',
	Metaverse = 'Metaverse',
	MetaverseMarket = 'Metaverse Market',
}
export enum DepartmentCode {
	Enft = 'ENFT',
}
export enum CheckoutType {
	Purchase = 'purchase',
	Offer = 'offer',
	Wine = 'wine',
	Metaverse = 'metaverse',
	MetaverseMarket = 'metaverse market',
}
export enum ErrorType {
	TechnicalError = 'technical error',
	RegistrationFailure = 'registration failure',
	ValidationError = 'validation error',
}
export enum PaymentMethod {
	Visa = 'visa',
	Amex = 'amex',
	Mc = 'mc',
	Crypto = 'crypto',
}
export enum StepName {
	ProfileInformation = 'Profile Information',
	BillingInformation = 'Billing Information',
	Review = 'Review',
	Submit = 'Submit',
}
export enum SaleType {
	Retail = 'retail',
	Lot = 'lot',
	PrivateSales = 'private-sales',
	Nft = 'nft',
}
export enum ItemType {
	Lot = 'lot',
	Auction = 'auction',
	Factsheet = 'factsheet',
	Retail = 'retail',
	Product = 'product',
	EditionSale = 'edition sale',
}
export enum ItemType1 {
	Auction1 = 'auction',
	EditionSale1 = 'edition sale',
	Factsheet1 = 'factsheet',
	Lot1 = 'lot',
	Product1 = 'product',
	Retail1 = 'retail',
	Secondary = 'secondary',
}
export enum Terms {
	AuctionTerms = 'auction terms',
	LimitedSaleTerms = 'limited sale terms',
	PrincipalAccept = 'principal accept',
}
export enum RejectedBy {
	Buyer = 'buyer',
	Seller = 'seller',
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: any) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(data6)
	ajv.addMetaSchema(data4)

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef AuctionRegistrationCompleted
 * @property {string} auction_registration_origin - Origin of the auction registration; passes \"auction details\" if registration related event occurred via the auction landing page
 * @property {string} auth0_id - User's Auth0 value
 * @property {BidMethod} [bid_method] - For Zaius, \"live_online\" when user registers for online bidding, \"telephone_bid\" with telephone registration, or \"sale_room_bid\" with paddle registration. 
Also used for Live sale bidding method selected during registration
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [enrollment_status] - Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {boolean | null} [preferred_member_flag] - If user is Preferred
 * @property {string[]} [product_id_list] - List of product_id values
 * @property {string | null} [registration_source] - Passes 'web' during live sale registration
 * @property {boolean | null} [restricted_enrollment_period] - Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period
 * @property {boolean | null} [restricted_sale] - Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef BidPlaced
 * @property {string} auth0_id - User's Auth0 value
 * @property {number} bid_amount - Maximum bid amount
 * @property {string} bid_currency - Currency of bid placed
 * @property {BidMethod} bid_method - For Zaius, \"live_online\" when user registers for online bidding, \"telephone_bid\" with telephone registration, or \"sale_room_bid\" with paddle registration. 
Also used for Live sale bidding method selected during registration
 * @property {BidResult} bid_result - Result of the bid placed
 * @property {ContentType} content_type - Section of the site
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {number} low_estimate - Low estimate of the product being interacted with
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {string} sale_id - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {string} sale_uuid - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef BidStarted
 * @property {string} auth0_id - User's Auth0 value
 * @property {number} bid_amount - Maximum bid amount
 * @property {string} bid_currency - Currency of bid placed
 * @property {BidMethod} bid_method - For Zaius, \"live_online\" when user registers for online bidding, \"telephone_bid\" with telephone registration, or \"sale_room_bid\" with paddle registration. 
Also used for Live sale bidding method selected during registration
 * @property {BidResult} bid_result - Result of the bid placed
 * @property {ContentType} content_type - Section of the site
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {string} sale_id - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {string} sale_uuid - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef CheckoutError
 * @property {string} auth0_id - User's Auth0 value
 * @property {string} cart_id - ID of the relevant cart. Sometimes referred to as bag_id.
 * @property {CheckoutType} checkout_type - Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {string | null} [error_code] - The internal error code of the error
 * @property {string} error_description - The description of the error that is received, e.g. \"bad postal code\"
 * @property {string} error_msg - The error message that the users sees on the front-end
 * @property {ErrorType} error_type - Error type
 * @property {PaymentMethod} payment_method - The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.
 * @property {number} revenue - Revenue associated with the transaction (excluding shipping and tax)
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string | null} [shipping_method] - Represents the shipping the method chosen (only pass this value if a shipping method is set)
 */
/**
 * @typedef CheckoutStarted
 * @property {string} auth0_id - User's Auth0 value
 * @property {string} cart_id - ID of the relevant cart. Sometimes referred to as bag_id.
 * @property {CheckoutType} checkout_type - Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased
 * @property {ContentType} content_type - Section of the site
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {number} revenue - Revenue associated with the transaction (excluding shipping and tax)
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 */
/**
 * @typedef ProductsItem
 * @property {string | null} [category] - Top level product category being viewed
 * @property {string | null} [category_2] - Second level product category being viewed
 * @property {string | null} [creator] - Creator associated with the creation of the product
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {string} period_general - The general period of the object
 * @property {number | null} [price] - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string} product_uuid - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} quantity - Quantity of the product
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string | null} [sale_name] - The name of the sale (auction or specific buy-now sale)
 * @property {string} sale_uuid - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 * @property {string | null} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} auth0_id - User's Auth0 value
 * @property {string} cart_id - ID of the relevant cart. Sometimes referred to as bag_id.
 * @property {CheckoutType} checkout_type - Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased
 * @property {ContentType} content_type - Section of the site
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {number} num_products - number of items selected
 * @property {PaymentMethod} [payment_method] - The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.
 * @property {ProductsItem[]} products - Properties of the product(s) related to the event
 * @property {number} revenue - Revenue associated with the transaction (excluding shipping and tax)
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 * @property {string} shipping_method - Represents the shipping the method chosen (only pass this value if a shipping method is set)
 * @property {number} step - Number representing the number of the a step in a process, e.g. \"1\" if the first step of registration was being completed
 * @property {StepName} step_name - Name of the step that was completed
 */
/**
 * @typedef ErrorOccurred
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {string} error_code - The internal error code of the error
 * @property {string} error_description - The description of the error that is received, e.g. \"bad postal code\"
 * @property {string} error_msg - The error message that the users sees on the front-end
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string | null} [sale_name] - The name of the sale (auction or specific buy-now sale)
 * @property {SaleType} sale_type - Type of sale
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef ExitLinkClicked
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string} external_link - URL of external (non sothebys.com) link
 * @property {boolean | null} [preferred_member_flag] - If user is Preferred
 */
/**
 * @typedef ItemFollowed
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {number | null} [customer_id] - Client system party ID
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {ItemType} [item_type] - The type of the item being viewed, followed or otherwise being interacted with
 * @property {string} language - Browsing language that user has selected via site header
 * @property {string | null} [product_id] - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {SaleType} [sale_type] - Type of sale
 */
/**
 * @typedef ItemSaved
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {ItemType} item_type - The type of the item being viewed, followed or otherwise being interacted with
 * @property {string} sale_id - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {SaleType} sale_type - Type of sale
 * @property {string} sale_uuid - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef ItemUnfollowed
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {number | null} [customer_id] - Client system party ID
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {ItemType1} [item_type] - The type of the item being viewed, followed or otherwise being interacted with
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {string | null} [product_id] - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {SaleType} [sale_type] - Type of sale
 */
/**
 * @typedef ItemUnsaved
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {ItemType} item_type - The type of the item being viewed, followed or otherwise being interacted with
 * @property {string} sale_id - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {SaleType} sale_type - Type of sale
 * @property {string} sale_uuid - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 */
/**
 * @typedef ListItemCompleted
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {string} [creator] - Creator associated with the creation of the product
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number} customer_id - Client system party ID
 * @property {string} department_category - Name of department(s) to which the event is related
 * @property {DepartmentCode} department_code - The internal code of the relevant department
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string} product_type - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {number} quantity - Quantity of the product
 * @property {string} token_type - The type of the relevant token in a metaverse listing or transaction
 */
/**
 * @typedef ListItemRemoved
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {string} [creator] - Creator associated with the creation of the product
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number} customer_id - Client system party ID
 * @property {string} department_category - Name of department(s) to which the event is related
 * @property {DepartmentCode} department_code - The internal code of the relevant department
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string} product_type - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {number} quantity - Quantity of the product
 * @property {string} token_type - The type of the relevant token in a metaverse listing or transaction
 */
/**
 * @typedef ListItemStarted
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {string} [creator] - Creator associated with the creation of the product
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string} product_type - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {number} quantity - Quantity of the product
 * @property {string} token_type - The type of the relevant token in a metaverse listing or transaction
 */
/**
 * @typedef ListItemStepCompleted
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {string} [creator] - Creator associated with the creation of the product
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number} customer_id - Client system party ID
 * @property {string} department_category - Name of department(s) to which the event is related
 * @property {DepartmentCode} department_code - The internal code of the relevant department
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string} product_type - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {number} quantity - Quantity of the product
 * @property {number} step - Number representing the number of the a step in a process, e.g. \"1\" if the first step of registration was being completed
 * @property {StepName} step_name - Name of the step that was completed
 * @property {string} token_type - The type of the relevant token in a metaverse listing or transaction
 */
/**
 * @typedef LogPageView
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string | null} [sale_name] - The name of the sale (auction or specific buy-now sale)
 */
/**
 * @typedef MakeAnOfferClicked
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {ContentType} [content_type] - Section of the site
 * @property {string | null} [creator] - Creator associated with the creation of the product
 * @property {string | null} [currency] - Currency code associated with the relevant product
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {string} image_url - Image URL of the product image
 * @property {boolean | null} [logged_in] - true if logged in
 * @property {string | null} [name] - name of the product
 * @property {boolean | null} [preferred_member_flag] - If user is Preferred
 * @property {number | null} [price] - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string | null} [product_id] - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string | null} [product_type] - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {number} quantity - Quantity of the product
 * @property {string} sku - Sku of the product
 * @property {string | null} [url] - URL of the product page
 */
/**
 * @typedef OfferCanceled
 * @property {string} auth0_id - User's Auth0 value
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number} customer_id - Client system party ID
 * @property {string} department_category - Name of department(s) to which the event is related
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} token_type - The type of the relevant token in a metaverse listing or transaction
 */
/**
 * @typedef OfferCompleted
 * @property {string} auth0_id - User's Auth0 value
 * @property {string | null} [cart_id] - ID of the relevant cart. Sometimes referred to as bag_id. 
 * @property {ContentType} content_type - Section of the site
 * @property {string | null} [creator] - Creator associated with the creation of the product
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {any | null} offer_id - Offer/transaction ID
 * @property {string} offer_number - Numerical number of offer
 * @property {number} offer_price - Offer amount the user enters
 * @property {number} percent_of_list_price - The percentage difference between list price and offer price
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string | null} [product_type] - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} quantity - Quantity of the product
 * @property {string} sale_id - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 * @property {string} sku - Sku of the product
 * @property {string | null} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef OfferRejected
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {string} creator - Creator associated with the creation of the product
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number} customer_id - Client system party ID
 * @property {string} department_category - Name of department(s) to which the event is related
 * @property {DepartmentCode} department_code - The internal code of the relevant department
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string} product_type - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} quantity - Quantity of the product
 */
/**
 * @typedef OfferStarted
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {string} creator - Creator associated with the creation of the product
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number} customer_id - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string | null} [product_type] - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} quantity - Quantity of the product
 */
/**
 * @typedef OfferStepCompleted
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {string} [creator] - Creator associated with the creation of the product
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number} customer_id - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {number} offer_price - Offer amount the user enters
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string | null} [product_type] - The object type of the product, e.g. \"Painting\" or \"Decor\". If we allow multiple product_type per product then concatenate the types with ',' (i.e. .join(','))
 * @property {string | null} [product_uuid] - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} quantity - Quantity of the product
 * @property {number} step - Number representing the number of the a step in a process, e.g. \"1\" if the first step of registration was being completed
 * @property {StepName} step_name - Name of the step that was completed
 */
/**
 * @typedef ProductsItem1
 * @property {string | null} [category] - Top level product category being viewed
 * @property {string | null} [category_2] - Second level product category being viewed
 * @property {string | null} [creator] - Creator associated with the creation of the product
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {string} period_general - The general period of the object
 * @property {number | null} [price] - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {string} product_uuid - The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.
 * @property {number} quantity - Quantity of the product
 * @property {string | null} [sale_id] - Six-digit SAP sale number
 * @property {string | null} [sale_name] - The name of the sale (auction or specific buy-now sale)
 * @property {string} sale_uuid - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 * @property {string | null} [variant] - The variant of the product (e.g. shoe size or bottle size)
 */
/**
 * @typedef OrderCompleted
 * @property {string} auth0_id - User's Auth0 value
 * @property {string} cart_id - ID of the relevant cart. Sometimes referred to as bag_id.
 * @property {CheckoutType} checkout_type - Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased
 * @property {ContentType} content_type - Section of the site
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string} department_category - Name of department(s) to which the event is related
 * @property {DepartmentCode} department_code - The internal code of the relevant department
 * @property {string[]} departments - The department(s) selling the item(s)
 * @property {number} fees - Amount of fees added to the order
 * @property {number} num_products - number of items selected
 * @property {any | null} order_id - The unique id created in the systems for this particular order
 * @property {PaymentMethod} payment_method - The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.
 * @property {ProductsItem1[]} products - Properties of the product(s) related to the event
 * @property {number} revenue - Revenue associated with the transaction (excluding shipping and tax)
 * @property {string} sale_id - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {string | null} [sale_uuid] - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 * @property {number} shipping - Shipping cost associated with the transaction
 * @property {number} tax - Total tax associated with the transaction
 * @property {number} total - Revenue with discounts and coupons added
 */
/**
 * @typedef SimpleRegistrationStart
 * @property {boolean | null} [in_app] - States whether the user is browsing the web from within the app or not
 * @property {string | null} [language] - Browsing language that user has selected via site header
 * @property {string} registration_source - Passes 'web' during live sale registration
 */
/**
 * @typedef SwapCompleted
 * @property {string} auth0_id - User's Auth0 value
 * @property {string} currency - Currency code associated with the relevant product
 * @property {number} customer_id - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {string} product_name - The name of the product or the relevant item
 * @property {number} revenue - Revenue associated with the transaction (excluding shipping and tax)
 * @property {number} total - Revenue with discounts and coupons added
 */
/**
 * @typedef TermsAccepted
 * @property {string} auth0_id - User's Auth0 value
 * @property {ContentType} content_type - Section of the site
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {DepartmentCode} [department_code] - The internal code of the relevant department
 * @property {boolean} in_app - States whether the user is browsing the web from within the app or not
 * @property {string} sale_id - Six-digit SAP sale number
 * @property {string} sale_name - The name of the sale (auction or specific buy-now sale)
 * @property {string} sale_uuid - The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.
 * @property {Terms} terms - Name of the terms that are being accepted, e.g. 'Auction Terms', 'Marketplace Terms', 'LFC Email Signup'
 */
/**
 * @typedef TransactionRejected
 * @property {string} auth0_id - User's Auth0 value
 * @property {string} [creator] - Creator associated with the creation of the product
 * @property {number} customer_id - Client system party ID
 * @property {string} department_category - Name of department(s) to which the event is related
 * @property {number} price - Price of the product.
List price of item for retail, low estimate for lot ask price for factsheet
 * @property {string} product_id - For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011
For buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id
 * @property {RejectedBy} rejected_by - Was the transaction or offer rejected by e.g. buyer or seller
 * @property {string} transaction_type - 
 */
/**
 * @typedef VideoPlaybackCompleted
 * @property {string} airdate - An ISO 8601 Date String representing the original air date or published date
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {string | null} [closed_captions] - Set to language selected in the Closed Caption dropdown
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {boolean | null} [full_episode] - true if content is a full episode and false otherwise
 * @property {boolean | null} [full_screen] - true if playback is currently in full screen mode and false otherwise
 * @property {string[]} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {boolean | null} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string | null} [playlist_display_name] - Playlist display name
 * @property {string | null} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number | null} [position] - The position (integer) of the thing being interacted with
 * @property {boolean | null} [preferred_member_flag] - If user is Preferred
 * @property {string | null} [quality] - The quality of the video
 * @property {number} sound - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number | null} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback
 * @property {string | null} [video_player] - Video player
 * @property {string | null} [video_title] - The title of the video
 */
/**
 * @typedef VideoPlaybackStarted
 * @property {string | null} [airdate] - An ISO 8601 Date String representing the original air date or published date
 * @property {string | null} [auth0_id] - User's Auth0 value
 * @property {string | null} [closed_captions] - Set to language selected in the Closed Caption dropdown
 * @property {number | null} [customer_id] - Client system party ID
 * @property {string | null} [department_category] - Name of department(s) to which the event is related
 * @property {boolean | null} [full_screen] - true if playback is currently in full screen mode and false otherwise
 * @property {string[]} [keywords] - An array of arbitrary keywords or tags that describe or categorize the video content
 * @property {boolean | null} [original_content] - true if content is Sotheby's original and false if licensed from 3rd party
 * @property {string | null} [playlist_display_name] - Playlist display name
 * @property {string | null} [playlist_internal_name] - Internal name of playlist if applicable
 * @property {number | null} [position] - The position (integer) of the thing being interacted with
 * @property {boolean | null} [preferred_member_flag] - If user is Preferred
 * @property {string | null} [quality] - The quality of the video
 * @property {number | null} [sound] - The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume
 * @property {number | null} [total_length] - The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback
 * @property {string | null} [video_player] - Video player
 * @property {string | null} [video_title] - The title of the video
 */

/**
 * User completes auction registration
 *
 * @param {AuctionRegistrationCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function auctionRegistrationCompleted(
	props: AuctionRegistrationCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User completes auction registration',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auction_registration_origin: {
						description:
							'Origin of the auction registration; passes \\"auction details\\" if registration related event occurred via the auction landing page',
						type: 'string',
					},
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					bid_method: {
						description:
							'For Zaius, \\"live_online\\" when user registers for online bidding, \\"telephone_bid\\" with telephone registration, or \\"sale_room_bid\\" with paddle registration. \nAlso used for Live sale bidding method selected during registration',
						enum: [
							'live_online',
							'advance_bid',
							'online_only',
							'absentee_bid',
							'telephone_bid',
							'sale_room_bid',
						],
						type: ['string', 'null'],
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					enrollment_status: {
						description:
							'Enrollment status of the user; only passes during the restricted period of a sale (i.e. when restricted period starts through close of sale); never passes for non-restricted sales',
						type: ['string', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: ['boolean', 'null'],
					},
					language: {
						description:
							'Browsing language that user has selected via site header',
						type: ['string', 'null'],
					},
					preferred_member_flag: {
						description: 'If user is Preferred',
						type: ['boolean', 'null'],
					},
					product_id_list: {
						description: 'List of product_id values',
						items: {
							type: ['string', 'null'],
						},
						type: 'array',
					},
					registration_source: {
						description: "Passes 'web' during live sale registration",
						type: ['string', 'null'],
					},
					restricted_enrollment_period: {
						description:
							"Passes only on restricted sales (otherwise do not pass) and is set to 'true' during the restricted sale period and 'false' before the restricted sale period",
						type: ['boolean', 'null'],
					},
					restricted_sale: {
						description:
							"Whether sale is restricted; should only pass as 'true' on sales that are restricted otherwise do not pass property",
						type: ['boolean', 'null'],
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: ['string', 'null'],
					},
				},
				required: ['auction_registration_origin', 'auth0_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Auction Registration Completed',
		type: 'object',
	}
	const message = {
		event: 'Auction Registration Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Auction Registration Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completes the bid flow and the server sends a response on the result of the bid (bid successful, bid too low)
 *
 * @param {BidPlaced} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bidPlaced(
	props: BidPlaced,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			'User completes the bid flow and the server sends a response on the result of the bid (bid successful, bid too low)',
		labels: {
			napa: 'avo',
		},
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					bid_amount: {
						description: 'Maximum bid amount',
						multipleOf: 1,
						type: 'number',
					},
					bid_currency: {
						description: 'Currency of bid placed',
						type: 'string',
					},
					bid_method: {
						description:
							'For Zaius, \\"live_online\\" when user registers for online bidding, \\"telephone_bid\\" with telephone registration, or \\"sale_room_bid\\" with paddle registration. \nAlso used for Live sale bidding method selected during registration',
						enum: [
							'live_online',
							'advance_bid',
							'online_only',
							'absentee_bid',
							'telephone_bid',
							'sale_room_bid',
						],
						type: 'string',
					},
					bid_result: {
						description: 'Result of the bid placed',
						enum: ['bid is with you', 'bid too low'],
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: 'boolean',
					},
					low_estimate: {
						description: 'Low estimate of the product being interacted with',
						type: 'number',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						type: 'string',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						type: 'string',
					},
					product_uuid: {
						description:
							'The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.',
						type: ['string', 'null'],
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: 'string',
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: 'string',
					},
				},
				required: [
					'sale_id',
					'auth0_id',
					'content_type',
					'in_app',
					'sale_name',
					'sale_uuid',
					'bid_amount',
					'bid_method',
					'bid_currency',
					'bid_result',
					'low_estimate',
					'product_id',
					'product_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Bid Placed',
		type: 'object',
	}
	const message = {
		event: 'Bid Placed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Bid Placed', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User clicks the Bid button to start the bid flow
 *
 * @param {BidStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bidStarted(
	props: BidStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User clicks the Bid button to start the bid flow',
		labels: {
			napa: 'avo',
		},
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					bid_amount: {
						description: 'Maximum bid amount',
						multipleOf: 1,
						type: 'number',
					},
					bid_currency: {
						description: 'Currency of bid placed',
						type: 'string',
					},
					bid_method: {
						description:
							'For Zaius, \\"live_online\\" when user registers for online bidding, \\"telephone_bid\\" with telephone registration, or \\"sale_room_bid\\" with paddle registration. \nAlso used for Live sale bidding method selected during registration',
						enum: [
							'live_online',
							'advance_bid',
							'online_only',
							'absentee_bid',
							'telephone_bid',
							'sale_room_bid',
						],
						type: 'string',
					},
					bid_result: {
						description: 'Result of the bid placed',
						enum: ['bid is with you', 'bid too low'],
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: 'boolean',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						type: 'string',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						type: 'string',
					},
					product_uuid: {
						description:
							'The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.',
						type: ['string', 'null'],
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: 'string',
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: 'string',
					},
				},
				required: [
					'sale_id',
					'auth0_id',
					'content_type',
					'in_app',
					'sale_name',
					'sale_uuid',
					'bid_amount',
					'bid_method',
					'bid_currency',
					'bid_result',
					'product_name',
					'product_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Bid Started',
		type: 'object',
	}
	const message = {
		event: 'Bid Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Bid Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees an error in the cart or checkout
 *
 * @param {CheckoutError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutError(
	props: CheckoutError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User sees an error in the cart or checkout',
		labels: {
			checkout: 'avo',
			metaverse: 'avo',
		},
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					cart_id: {
						description:
							'ID of the relevant cart. Sometimes referred to as bag_id. ',
						type: 'string',
					},
					checkout_type: {
						description:
							'Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased',
						enum: [
							'purchase',
							'offer',
							'wine',
							'metaverse',
							'metaverse market',
						],
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					error_code: {
						description: 'The internal error code of the error',
						type: ['string', 'null'],
					},
					error_description: {
						description:
							'The description of the error that is received, e.g. \\"bad postal code\\"',
						type: 'string',
					},
					error_msg: {
						description:
							'The error message that the users sees on the front-end',
						type: 'string',
					},
					error_type: {
						description: 'Error type',
						enum: [
							'technical error',
							'registration failure',
							'validation error',
						],
						type: 'string',
					},
					payment_method: {
						description:
							'The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.',
						enum: ['visa', 'amex', 'mc', 'crypto'],
						type: 'string',
					},
					revenue: {
						description:
							'Revenue associated with the transaction (excluding shipping and tax)',
						type: 'number',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: ['string', 'null'],
					},
					shipping_method: {
						description:
							'Represents the shipping the method chosen (only pass this value if a shipping method is set)',
						type: ['string', 'null'],
					},
				},
				required: [
					'auth0_id',
					'payment_method',
					'revenue',
					'checkout_type',
					'error_msg',
					'error_type',
					'error_description',
					'cart_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Checkout Error',
		type: 'object',
	}
	const message = {
		event: 'Checkout Error',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Checkout Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User starts a checkout of a cart by clicking a ''Checkout Now" button when the user is logged in. Event triggers: 1. Sent when a user succesfuly starts a checkout by pressing the checkout button while logged in. Trigger on all sources. View in Avo: https://www.avo.app/schemas/sqtpEbdMfEfjjyqszKnR/branches/bsvCEtz6s/events/R2qKtMiwey/trigger/Hag3oBfgz
 *
 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
	props: CheckoutStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			"User starts a checkout of a cart by clicking a ''Checkout Now\" button when the user is logged in. Event triggers: 1. Sent when a user succesfuly starts a checkout by pressing the checkout button while logged in. Trigger on all sources. View in Avo: https://www.avo.app/schemas/sqtpEbdMfEfjjyqszKnR/branches/bsvCEtz6s/events/R2qKtMiwey/trigger/Hag3oBfgz",
		labels: {
			checkout: 'avo',
			metaverse: 'avo',
		},
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					cart_id: {
						description:
							'ID of the relevant cart. Sometimes referred to as bag_id. ',
						type: 'string',
					},
					checkout_type: {
						description:
							'Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased',
						enum: [
							'purchase',
							'offer',
							'wine',
							'metaverse',
							'metaverse market',
						],
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					revenue: {
						description:
							'Revenue associated with the transaction (excluding shipping and tax)',
						type: 'number',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: ['string', 'null'],
					},
				},
				required: [
					'auth0_id',
					'checkout_type',
					'currency',
					'revenue',
					'content_type',
					'cart_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Checkout Started',
		type: 'object',
	}
	const message = {
		event: 'Checkout Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed a checkout step
 *
 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(
	props: CheckoutStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User completed a checkout step',
		labels: {
			checkout: 'avo',
			metaverse: 'avo',
		},
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					cart_id: {
						description:
							'ID of the relevant cart. Sometimes referred to as bag_id. ',
						type: 'string',
					},
					checkout_type: {
						description:
							'Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased',
						enum: [
							'purchase',
							'offer',
							'wine',
							'metaverse',
							'metaverse market',
						],
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: 'boolean',
					},
					num_products: {
						description: 'number of items selected',
						multipleOf: 1,
						type: 'number',
					},
					payment_method: {
						description:
							'The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.',
						enum: ['visa', 'amex', 'mc', 'crypto'],
						type: ['string', 'null'],
					},
					products: {
						description: 'Properties of the product(s) related to the event',
						items: {
							additionalProperties: false,
							properties: {
								category: {
									description: 'Top level product category being viewed',
									type: ['string', 'null'],
								},
								category_2: {
									description: 'Second level product category being viewed',
									type: ['string', 'null'],
								},
								creator: {
									description:
										'Creator associated with the creation of the product',
									type: ['string', 'null'],
								},
								department_category: {
									description:
										'Name of department(s) to which the event is related',
									type: ['string', 'null'],
								},
								period_general: {
									description: 'The general period of the object',
									type: 'string',
								},
								price: {
									description:
										'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
									type: ['number', 'null'],
								},
								product_id: {
									description:
										'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
									type: 'string',
								},
								product_name: {
									description: 'The name of the product or the relevant item',
									type: 'string',
								},
								product_uuid: {
									description:
										'The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.',
									type: 'string',
								},
								quantity: {
									description: 'Quantity of the product',
									multipleOf: 1,
									type: 'number',
								},
								sale_id: {
									description: 'Six-digit SAP sale number',
									type: ['string', 'null'],
								},
								sale_name: {
									description:
										'The name of the sale (auction or specific buy-now sale)',
									type: ['string', 'null'],
								},
								sale_uuid: {
									description:
										'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
									type: 'string',
								},
								variant: {
									description:
										'The variant of the product (e.g. shoe size or bottle size)',
									type: ['string', 'null'],
								},
							},
							required: [
								'product_id',
								'sale_uuid',
								'product_name',
								'quantity',
								'product_uuid',
								'period_general',
							],
							type: 'object',
						},
						type: 'array',
					},
					revenue: {
						description:
							'Revenue associated with the transaction (excluding shipping and tax)',
						type: 'number',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: ['string', 'null'],
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: ['string', 'null'],
					},
					shipping_method: {
						description:
							'Represents the shipping the method chosen (only pass this value if a shipping method is set)',
						type: 'string',
					},
					step: {
						description:
							'Number representing the number of the a step in a process, e.g. \\"1\\" if the first step of registration was being completed',
						multipleOf: 1,
						type: 'number',
					},
					step_name: {
						description: 'Name of the step that was completed',
						enum: [
							'Profile Information',
							'Billing Information',
							'Review',
							'Submit',
						],
						type: 'string',
					},
				},
				required: [
					'num_products',
					'checkout_type',
					'shipping_method',
					'products',
					'revenue',
					'cart_id',
					'auth0_id',
					'content_type',
					'in_app',
					'sale_name',
					'step',
					'step_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Checkout Step Completed',
		type: 'object',
	}
	const message = {
		event: 'Checkout Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when an error occurs, on any page or action
 *
 * @param {ErrorOccurred} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorOccurred(
	props: ErrorOccurred,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'Sent when an error occurs, on any page or action',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: ['string', 'null'],
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					error_code: {
						description: 'The internal error code of the error',
						type: 'string',
					},
					error_description: {
						description:
							'The description of the error that is received, e.g. \\"bad postal code\\"',
						type: 'string',
					},
					error_msg: {
						description:
							'The error message that the users sees on the front-end',
						type: 'string',
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: 'boolean',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: ['string', 'null'],
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: ['string', 'null'],
					},
					sale_type: {
						description: 'Type of sale',
						enum: ['retail', 'lot', 'private-sales', 'nft'],
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: ['string', 'null'],
					},
				},
				required: [
					'content_type',
					'in_app',
					'error_description',
					'sale_type',
					'error_msg',
					'error_code',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Error Occurred',
		type: 'object',
	}
	const message = {
		event: 'Error Occurred',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Error Occurred',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks non-sothebys.com domain link to exit the website
 *
 * @param {ExitLinkClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exitLinkClicked(
	props: ExitLinkClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User clicks non-sothebys.com domain link to exit the website',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: ['string', 'null'],
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					external_link: {
						description: 'URL of external (non sothebys.com) link',
						type: 'string',
					},
					preferred_member_flag: {
						description: 'If user is Preferred',
						type: ['boolean', 'null'],
					},
				},
				required: ['external_link'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Exit Link Clicked',
		type: 'object',
	}
	const message = {
		event: 'Exit Link Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Exit Link Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Item Followed' track call.
 *
 * @param {ItemFollowed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemFollowed(
	props: ItemFollowed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: ['string', 'null'],
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						id: '/properties/properties/properties/in_app',
						type: 'boolean',
					},
					item_type: {
						description:
							'The type of the item being viewed, followed or otherwise being interacted with',
						enum: [
							'lot',
							'auction',
							'factsheet',
							'retail',
							'product',
							'edition sale',
						],
						id: '/properties/properties/properties/item_type',
						type: ['string', 'null'],
					},
					language: {
						description:
							'Browsing language that user has selected via site header',
						id: '/properties/properties/properties/language',
						type: 'string',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						id: '/properties/properties/properties/product_id',
						type: ['string', 'null'],
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						id: '/properties/properties/properties/sale_id',
						type: ['string', 'null'],
					},
					sale_type: {
						description: 'Type of sale',
						enum: ['retail', 'lot', 'private-sales', 'nft'],
						id: '/properties/properties/properties/sale_type',
						type: ['string', 'null'],
					},
				},
				required: ['in_app', 'language'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Item Followed',
		type: 'object',
	}
	const message = {
		event: 'Item Followed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Item Followed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a user saves an item, e.g. clicks the Save button on a lot
 *
 * @param {ItemSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemSaved(
	props: ItemSaved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			'Sent when a user saves an item, e.g. clicks the Save button on a lot',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: 'boolean',
					},
					item_type: {
						description:
							'The type of the item being viewed, followed or otherwise being interacted with',
						enum: [
							'lot',
							'auction',
							'factsheet',
							'retail',
							'product',
							'edition sale',
						],
						type: 'string',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: 'string',
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: 'string',
					},
					sale_type: {
						description: 'Type of sale',
						enum: ['retail', 'lot', 'private-sales', 'nft'],
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: 'string',
					},
				},
				required: [
					'sale_id',
					'auth0_id',
					'content_type',
					'in_app',
					'sale_name',
					'sale_uuid',
					'item_type',
					'sale_type',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Item Saved',
		type: 'object',
	}
	const message = {
		event: 'Item Saved',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Item Saved', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Fires a 'Item Unfollowed' track call.
 *
 * @param {ItemUnfollowed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemUnfollowed(
	props?: ItemUnfollowed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: ['string', 'null'],
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						id: '/properties/properties/properties/in_app',
						type: ['boolean', 'null'],
					},
					item_type: {
						description:
							'The type of the item being viewed, followed or otherwise being interacted with',
						enum: [
							'auction',
							'edition sale',
							'factsheet',
							'lot',
							'product',
							'retail',
							'secondary',
						],
						id: '/properties/properties/properties/item_type',
						type: ['string', 'null'],
					},
					language: {
						description:
							'Browsing language that user has selected via site header',
						id: '/properties/properties/properties/language',
						type: ['string', 'null'],
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						id: '/properties/properties/properties/product_id',
						type: ['string', 'null'],
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						id: '/properties/properties/properties/sale_id',
						type: ['string', 'null'],
					},
					sale_type: {
						description: 'Type of sale',
						enum: ['retail', 'lot', 'private-sales', 'nft'],
						id: '/properties/properties/properties/sale_type',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Item Unfollowed',
		type: 'object',
	}
	const message = {
		event: 'Item Unfollowed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Item Unfollowed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when a user unsaves an item , e.g. clicks the Unsave button on a lot
 *
 * @param {ItemUnsaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemUnsaved(
	props: ItemUnsaved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			'Sent when a user unsaves an item , e.g. clicks the Unsave button on a lot',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: 'boolean',
					},
					item_type: {
						description:
							'The type of the item being viewed, followed or otherwise being interacted with',
						enum: [
							'lot',
							'auction',
							'factsheet',
							'retail',
							'product',
							'edition sale',
						],
						type: 'string',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: 'string',
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: 'string',
					},
					sale_type: {
						description: 'Type of sale',
						enum: ['retail', 'lot', 'private-sales', 'nft'],
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: 'string',
					},
				},
				required: [
					'sale_id',
					'auth0_id',
					'content_type',
					'in_app',
					'sale_name',
					'sale_uuid',
					'item_type',
					'sale_type',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Item Unsaved',
		type: 'object',
	}
	const message = {
		event: 'Item Unsaved',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Item Unsaved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A listing on the metaverse marketplace is completed successfully
 *
 * @param {ListItemCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function listItemCompleted(
	props: ListItemCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			'A listing on the metaverse marketplace is completed successfully',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						id: '/properties/properties/properties/content_type',
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						id: '/properties/properties/properties/creator',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						id: '/properties/properties/properties/currency',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						id: '/properties/properties/properties/department_category',
						type: 'string',
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						id: '/properties/properties/properties/department_code',
						type: 'string',
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						id: '/properties/properties/properties/price',
						type: 'number',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						id: '/properties/properties/properties/product_id',
						type: 'string',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						id: '/properties/properties/properties/product_name',
						type: 'string',
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						id: '/properties/properties/properties/product_type',
						type: 'string',
					},
					quantity: {
						description: 'Quantity of the product',
						id: '/properties/properties/properties/quantity',
						multipleOf: 1,
						type: 'number',
					},
					token_type: {
						description:
							'The type of the relevant token in a metaverse listing or transaction',
						id: '/properties/properties/properties/token_type',
						type: 'string',
					},
				},
				required: [
					'auth0_id',
					'customer_id',
					'department_category',
					'department_code',
					'content_type',
					'currency',
					'product_id',
					'product_type',
					'quantity',
					'price',
					'product_name',
					'token_type',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'List Item Completed',
		type: 'object',
	}
	const message = {
		event: 'List Item Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'List Item Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A listing on the metaverse marketplace is completed removed
 *
 * @param {ListItemRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function listItemRemoved(
	props: ListItemRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'A listing on the metaverse marketplace is completed removed',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						id: '/properties/properties/properties/content_type',
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						id: '/properties/properties/properties/creator',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						id: '/properties/properties/properties/currency',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						id: '/properties/properties/properties/department_category',
						type: 'string',
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						id: '/properties/properties/properties/department_code',
						type: 'string',
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						id: '/properties/properties/properties/price',
						type: 'number',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						id: '/properties/properties/properties/product_id',
						type: 'string',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						id: '/properties/properties/properties/product_name',
						type: 'string',
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						id: '/properties/properties/properties/product_type',
						type: 'string',
					},
					quantity: {
						description: 'Quantity of the product',
						id: '/properties/properties/properties/quantity',
						multipleOf: 1,
						type: 'number',
					},
					token_type: {
						description:
							'The type of the relevant token in a metaverse listing or transaction',
						id: '/properties/properties/properties/token_type',
						type: 'string',
					},
				},
				required: [
					'auth0_id',
					'customer_id',
					'department_category',
					'department_code',
					'content_type',
					'currency',
					'product_id',
					'product_type',
					'quantity',
					'price',
					'product_name',
					'token_type',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'List Item Removed',
		type: 'object',
	}
	const message = {
		event: 'List Item Removed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'List Item Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A listing on the metaverse marketplace is started by clicking a Create Listing button
 *
 * @param {ListItemStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function listItemStarted(
	props: ListItemStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			'A listing on the metaverse marketplace is started by clicking a Create Listing button',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						id: '/properties/properties/properties/content_type',
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						id: '/properties/properties/properties/creator',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						id: '/properties/properties/properties/currency',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						id: '/properties/properties/properties/department_category',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						id: '/properties/properties/properties/department_code',
						type: ['string', 'null'],
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						id: '/properties/properties/properties/price',
						type: 'number',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						id: '/properties/properties/properties/product_id',
						type: 'string',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						id: '/properties/properties/properties/product_name',
						type: 'string',
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						id: '/properties/properties/properties/product_type',
						type: 'string',
					},
					quantity: {
						description: 'Quantity of the product',
						id: '/properties/properties/properties/quantity',
						multipleOf: 1,
						type: 'number',
					},
					token_type: {
						description:
							'The type of the relevant token in a metaverse listing or transaction',
						id: '/properties/properties/properties/token_type',
						type: 'string',
					},
				},
				required: [
					'auth0_id',
					'content_type',
					'currency',
					'product_id',
					'product_type',
					'quantity',
					'price',
					'product_name',
					'token_type',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'List Item Started',
		type: 'object',
	}
	const message = {
		event: 'List Item Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'List Item Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A step towards creating a listing on the metaverse marketplace is completed
 *
 * @param {ListItemStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function listItemStepCompleted(
	props: ListItemStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			'A step towards creating a listing on the metaverse marketplace is completed',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						id: '/properties/properties/properties/content_type',
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						id: '/properties/properties/properties/creator',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						id: '/properties/properties/properties/currency',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						id: '/properties/properties/properties/department_category',
						type: 'string',
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						id: '/properties/properties/properties/department_code',
						type: 'string',
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						id: '/properties/properties/properties/price',
						type: 'number',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						id: '/properties/properties/properties/product_id',
						type: 'string',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						id: '/properties/properties/properties/product_name',
						type: 'string',
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						id: '/properties/properties/properties/product_type',
						type: 'string',
					},
					quantity: {
						description: 'Quantity of the product',
						id: '/properties/properties/properties/quantity',
						multipleOf: 1,
						type: 'number',
					},
					step: {
						description:
							'Number representing the number of the a step in a process, e.g. \\"1\\" if the first step of registration was being completed',
						id: '/properties/properties/properties/step',
						multipleOf: 1,
						type: 'number',
					},
					step_name: {
						description: 'Name of the step that was completed',
						enum: [
							'Profile Information',
							'Billing Information',
							'Review',
							'Submit',
						],
						id: '/properties/properties/properties/step_name',
						type: 'string',
					},
					token_type: {
						description:
							'The type of the relevant token in a metaverse listing or transaction',
						id: '/properties/properties/properties/token_type',
						type: 'string',
					},
				},
				required: [
					'auth0_id',
					'customer_id',
					'department_category',
					'department_code',
					'content_type',
					'currency',
					'product_id',
					'product_type',
					'quantity',
					'price',
					'product_name',
					'step',
					'step_name',
					'token_type',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'List Item Step Completed',
		type: 'object',
	}
	const message = {
		event: 'List Item Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'List Item Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * An event that only triggers a .page call in Segment
 *
 * @param {LogPageView} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function logPageView(
	props: LogPageView,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'An event that only triggers a .page call in Segment',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: ['string', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: ['string', 'null'],
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Log Page View',
		type: 'object',
	}
	const message = {
		event: 'Log Page View',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Log Page View',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the ''Make an Offer" button.
 *
 * @param {MakeAnOfferClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function makeAnOfferClicked(
	props: MakeAnOfferClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: "User clicks the ''Make an Offer\" button.",
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: ['string', 'null'],
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						id: '/properties/properties/properties/content_type',
						type: ['string', 'null'],
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						id: '/properties/properties/properties/creator',
						type: ['string', 'null'],
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						id: '/properties/properties/properties/currency',
						type: ['string', 'null'],
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						id: '/properties/properties/properties/department_category',
						type: ['string', 'null'],
					},
					image_url: {
						description: 'Image URL of the product image',
						id: '/properties/properties/properties/image_url',
						type: 'string',
					},
					logged_in: {
						description: 'true if logged in',
						id: '/properties/properties/properties/logged_in',
						type: ['boolean', 'null'],
					},
					name: {
						description: 'name of the product',
						id: '/properties/properties/properties/name',
						type: ['string', 'null'],
					},
					preferred_member_flag: {
						description: 'If user is Preferred',
						id: '/properties/properties/properties/preferred_member_flag',
						type: ['boolean', 'null'],
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						id: '/properties/properties/properties/price',
						type: ['number', 'null'],
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						id: '/properties/properties/properties/product_id',
						type: ['string', 'null'],
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						id: '/properties/properties/properties/product_type',
						type: ['string', 'null'],
					},
					quantity: {
						description: 'Quantity of the product',
						id: '/properties/properties/properties/quantity',
						multipleOf: 1,
						type: 'number',
					},
					sku: {
						description: 'Sku of the product',
						id: '/properties/properties/properties/sku',
						type: 'string',
					},
					url: {
						description: 'URL of the product page',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['image_url', 'quantity', 'sku'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Make an Offer Clicked',
		type: 'object',
	}
	const message = {
		event: 'Make an Offer Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Make an Offer Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Offer canceled by... buyer/seller?
 *
 * @param {OfferCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerCanceled(
	props: OfferCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'Offer canceled by... buyer/seller?',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: 'string',
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						type: 'number',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						type: 'string',
					},
					token_type: {
						description:
							'The type of the relevant token in a metaverse listing or transaction',
						type: 'string',
					},
				},
				required: [
					'auth0_id',
					'customer_id',
					'department_category',
					'product_id',
					'price',
					'currency',
					'token_type',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Offer Canceled',
		type: 'object',
	}
	const message = {
		event: 'Offer Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User submitted an offer. Sent from the confirmation page
 *
 * @param {OfferCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerCompleted(
	props: OfferCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User submitted an offer. Sent from the confirmation page',
		labels: {
			checkout: 'avo',
		},
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					cart_id: {
						description:
							'ID of the relevant cart. Sometimes referred to as bag_id. ',
						type: ['string', 'null'],
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						type: ['string', 'null'],
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					offer_id: {
						description: 'Offer/transaction ID',
					},
					offer_number: {
						description: 'Numerical number of offer',
						type: 'string',
					},
					offer_price: {
						description: 'Offer amount the user enters',
						type: 'number',
					},
					percent_of_list_price: {
						description:
							'The percentage difference between list price and offer price',
						type: 'number',
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						type: 'number',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						type: 'string',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						type: 'string',
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						type: ['string', 'null'],
					},
					product_uuid: {
						description:
							'The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.',
						type: ['string', 'null'],
					},
					quantity: {
						description: 'Quantity of the product',
						multipleOf: 1,
						type: 'number',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: 'string',
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: ['string', 'null'],
					},
					sku: {
						description: 'Sku of the product',
						type: 'string',
					},
					variant: {
						description:
							'The variant of the product (e.g. shoe size or bottle size)',
						type: ['string', 'null'],
					},
				},
				required: [
					'sale_id',
					'auth0_id',
					'content_type',
					'sale_name',
					'currency',
					'product_id',
					'sku',
					'quantity',
					'price',
					'product_name',
					'offer_id',
					'offer_price',
					'offer_number',
					'percent_of_list_price',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Offer Completed',
		type: 'object',
	}
	const message = {
		event: 'Offer Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Offer rejected by user
 *
 * @param {OfferRejected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerRejected(
	props: OfferRejected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'Offer rejected by user',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: 'string',
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: 'string',
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						type: 'number',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						type: 'string',
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						type: 'string',
					},
					product_uuid: {
						description:
							'The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.',
						type: ['string', 'null'],
					},
					quantity: {
						description: 'Quantity of the product',
						multipleOf: 1,
						type: 'number',
					},
				},
				required: [
					'auth0_id',
					'customer_id',
					'department_category',
					'department_code',
					'content_type',
					'creator',
					'currency',
					'product_type',
					'quantity',
					'price',
					'product_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Offer Rejected',
		type: 'object',
	}
	const message = {
		event: 'Offer Rejected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Rejected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Offer Started' track call.
 *
 * @param {OfferStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerStarted(
	props: OfferStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						type: 'number',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						type: 'string',
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						type: ['string', 'null'],
					},
					product_uuid: {
						description:
							'The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.',
						type: ['string', 'null'],
					},
					quantity: {
						description: 'Quantity of the product',
						multipleOf: 1,
						type: 'number',
					},
				},
				required: [
					'auth0_id',
					'customer_id',
					'content_type',
					'creator',
					'currency',
					'quantity',
					'price',
					'product_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Offer Started',
		type: 'object',
	}
	const message = {
		event: 'Offer Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Offer Step Completed' track call.
 *
 * @param {OfferStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerStepCompleted(
	props: OfferStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						id: '/properties/properties/properties/content_type',
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						id: '/properties/properties/properties/creator',
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						id: '/properties/properties/properties/currency',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						id: '/properties/properties/properties/department_category',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						id: '/properties/properties/properties/department_code',
						type: ['string', 'null'],
					},
					offer_price: {
						description: 'Offer amount the user enters',
						id: '/properties/properties/properties/offer_price',
						type: 'number',
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						id: '/properties/properties/properties/price',
						type: 'number',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						id: '/properties/properties/properties/product_name',
						type: 'string',
					},
					product_type: {
						description:
							'The object type of the product, e.g. \\"Painting\\" or \\"Decor\\". If we allow multiple product_type per product then concatenate the types with \',\' (i.e. .join(\',\'))',
						id: '/properties/properties/properties/product_type',
						type: ['string', 'null'],
					},
					product_uuid: {
						description:
							'The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.',
						id: '/properties/properties/properties/product_uuid',
						type: ['string', 'null'],
					},
					quantity: {
						description: 'Quantity of the product',
						id: '/properties/properties/properties/quantity',
						multipleOf: 1,
						type: 'number',
					},
					step: {
						description:
							'Number representing the number of the a step in a process, e.g. \\"1\\" if the first step of registration was being completed',
						id: '/properties/properties/properties/step',
						multipleOf: 1,
						type: 'number',
					},
					step_name: {
						description: 'Name of the step that was completed',
						enum: [
							'Profile Information',
							'Billing Information',
							'Review',
							'Submit',
						],
						id: '/properties/properties/properties/step_name',
						type: 'string',
					},
				},
				required: [
					'auth0_id',
					'customer_id',
					'content_type',
					'currency',
					'quantity',
					'price',
					'product_name',
					'offer_price',
					'step',
					'step_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Offer Step Completed',
		type: 'object',
	}
	const message = {
		event: 'Offer Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed the order
 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(
	props: OrderCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User completed the order',
		labels: {
			checkout: 'avo',
			metaverse: 'avo',
		},
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					cart_id: {
						description:
							'ID of the relevant cart. Sometimes referred to as bag_id. ',
						type: 'string',
					},
					checkout_type: {
						description:
							'Describes the type of relevant checkout, used to differ between retail purchases, offers being made or wine being purchased',
						enum: [
							'purchase',
							'offer',
							'wine',
							'metaverse',
							'metaverse market',
						],
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: 'string',
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: 'string',
					},
					departments: {
						description: 'The department(s) selling the item(s)',
						items: {
							type: 'string',
						},
						type: 'array',
					},
					fees: {
						description: 'Amount of fees added to the order',
						type: 'number',
					},
					num_products: {
						description: 'number of items selected',
						multipleOf: 1,
						type: 'number',
					},
					order_id: {
						description:
							'The unique id created in the systems for this particular order',
					},
					payment_method: {
						description:
							'The payment method used to pay for the purchase. These are either name of credit card companies, name of bank transfer protocol or name of a crypto wallet option.',
						enum: ['visa', 'amex', 'mc', 'crypto'],
						type: 'string',
					},
					products: {
						description: 'Properties of the product(s) related to the event',
						items: {
							additionalProperties: false,
							properties: {
								category: {
									description: 'Top level product category being viewed',
									type: ['string', 'null'],
								},
								category_2: {
									description: 'Second level product category being viewed',
									type: ['string', 'null'],
								},
								creator: {
									description:
										'Creator associated with the creation of the product',
									type: ['string', 'null'],
								},
								department_category: {
									description:
										'Name of department(s) to which the event is related',
									type: ['string', 'null'],
								},
								period_general: {
									description: 'The general period of the object',
									type: 'string',
								},
								price: {
									description:
										'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
									type: ['number', 'null'],
								},
								product_id: {
									description:
										'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
									type: 'string',
								},
								product_name: {
									description: 'The name of the product or the relevant item',
									type: 'string',
								},
								product_uuid: {
									description:
										'The uuid of the product, created in the system that created the sale or product, i.e. Viking, metaverse etc.',
									type: 'string',
								},
								quantity: {
									description: 'Quantity of the product',
									multipleOf: 1,
									type: 'number',
								},
								sale_id: {
									description: 'Six-digit SAP sale number',
									type: ['string', 'null'],
								},
								sale_name: {
									description:
										'The name of the sale (auction or specific buy-now sale)',
									type: ['string', 'null'],
								},
								sale_uuid: {
									description:
										'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
									type: 'string',
								},
								variant: {
									description:
										'The variant of the product (e.g. shoe size or bottle size)',
									type: ['string', 'null'],
								},
							},
							required: [
								'product_id',
								'sale_uuid',
								'product_name',
								'quantity',
								'product_uuid',
								'period_general',
							],
							type: 'object',
						},
						type: 'array',
					},
					revenue: {
						description:
							'Revenue associated with the transaction (excluding shipping and tax)',
						type: 'number',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: 'string',
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: ['string', 'null'],
					},
					shipping: {
						description: 'Shipping cost associated with the transaction',
						type: 'number',
					},
					tax: {
						description: 'Total tax associated with the transaction',
						type: 'number',
					},
					total: {
						description: 'Revenue with discounts and coupons added',
						type: 'number',
					},
				},
				required: [
					'sale_id',
					'auth0_id',
					'department_category',
					'content_type',
					'sale_name',
					'department_code',
					'num_products',
					'checkout_type',
					'payment_method',
					'products',
					'revenue',
					'cart_id',
					'total',
					'tax',
					'shipping',
					'departments',
					'currency',
					'order_id',
					'fees',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Order Completed',
		type: 'object',
	}
	const message = {
		event: 'Order Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User starts simple registration
 *
 * @param {SimpleRegistrationStart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function simpleRegistrationStart(
	props: SimpleRegistrationStart,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User starts simple registration',
		labels: {
			legacy: 'avo',
		},
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: ['boolean', 'null'],
					},
					language: {
						description:
							'Browsing language that user has selected via site header',
						type: ['string', 'null'],
					},
					registration_source: {
						description: "Passes 'web' during live sale registration",
						type: 'string',
					},
				},
				required: ['registration_source'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Simple Registration Start',
		type: 'object',
	}
	const message = {
		event: 'Simple Registration Start',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Simple Registration Start',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Swap Completed' track call.
 *
 * @param {SwapCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function swapCompleted(
	props: SwapCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					currency: {
						description: 'Currency code associated with the relevant product',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						type: 'string',
					},
					product_name: {
						description: 'The name of the product or the relevant item',
						type: 'string',
					},
					revenue: {
						description:
							'Revenue associated with the transaction (excluding shipping and tax)',
						type: 'number',
					},
					total: {
						description: 'Revenue with discounts and coupons added',
						type: 'number',
					},
				},
				required: [
					'auth0_id',
					'currency',
					'total',
					'revenue',
					'customer_id',
					'product_name',
					'product_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Swap Completed',
		type: 'object',
	}
	const message = {
		event: 'Swap Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Swap Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User consents to terms and conditions that are presented. This is used for any type of terms, e.g. auction terms, email list terms and more
 *
 * @param {TermsAccepted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function termsAccepted(
	props: TermsAccepted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			'User consents to terms and conditions that are presented. This is used for any type of terms, e.g. auction terms, email list terms and more',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						type: 'string',
					},
					content_type: {
						description: 'Section of the site',
						enum: [
							'Buy Now',
							'Auctions',
							'Private Sale',
							'Metaverse',
							'Metaverse Market',
						],
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					department_code: {
						description: 'The internal code of the relevant department',
						enum: ['ENFT'],
						type: ['string', 'null'],
					},
					in_app: {
						description:
							'States whether the user is browsing the web from within the app or not',
						type: 'boolean',
					},
					sale_id: {
						description: 'Six-digit SAP sale number',
						type: 'string',
					},
					sale_name: {
						description:
							'The name of the sale (auction or specific buy-now sale)',
						type: 'string',
					},
					sale_uuid: {
						description:
							'The uuid of the sale, created in the system that created the sale or product, i.e. auction_id in Viking, sale_uuid in metaverse etc.',
						type: 'string',
					},
					terms: {
						description:
							"Name of the terms that are being accepted, e.g. 'Auction Terms', 'Marketplace Terms', 'LFC Email Signup'",
						enum: ['auction terms', 'limited sale terms', 'principal accept'],
						type: 'string',
					},
				},
				required: [
					'sale_id',
					'auth0_id',
					'content_type',
					'in_app',
					'sale_name',
					'sale_uuid',
					'terms',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Terms Accepted',
		type: 'object',
	}
	const message = {
		event: 'Terms Accepted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Terms Accepted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Transaction Rejected by seller of buyer, e.g. in a secondary makerketplace
 *
 * @param {TransactionRejected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transactionRejected(
	props: TransactionRejected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description:
			'Transaction Rejected by seller of buyer, e.g. in a secondary makerketplace',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					auth0_id: {
						description: "User's Auth0 value",
						id: '/properties/properties/properties/auth0_id',
						type: 'string',
					},
					creator: {
						description: 'Creator associated with the creation of the product',
						id: '/properties/properties/properties/creator',
						type: 'string',
					},
					customer_id: {
						description: 'Client system party ID',
						id: '/properties/properties/properties/customer_id',
						multipleOf: 1,
						type: 'number',
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						id: '/properties/properties/properties/department_category',
						type: 'string',
					},
					price: {
						description:
							'Price of the product.\nList price of item for retail, low estimate for lot ask price for factsheet',
						id: '/properties/properties/properties/price',
						type: 'number',
					},
					product_id: {
						description:
							'For auctions: Six-digit SAP sale number plus lot number (4 digits), e.g. N100230011\nFor buy now: use same id as for SKU, i.e. the sap_id a.k.a. external_id',
						id: '/properties/properties/properties/product_id',
						type: 'string',
					},
					rejected_by: {
						description:
							'Was the transaction or offer rejected by e.g. buyer or seller',
						enum: ['buyer', 'seller'],
						id: '/properties/properties/properties/rejected_by',
						type: 'string',
					},
					transaction_type: {
						description: '',
						id: '/properties/properties/properties/transaction_type',
						type: 'string',
					},
				},
				required: [
					'auth0_id',
					'customer_id',
					'department_category',
					'transaction_type',
					'product_id',
					'price',
					'rejected_by',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Transaction Rejected',
		type: 'object',
	}
	const message = {
		event: 'Transaction Rejected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Transaction Rejected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Video playback reaches 100%
 *
 * @param {VideoPlaybackCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackCompleted(
	props: VideoPlaybackCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'Video playback reaches 100%',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					airdate: {
						description:
							'An ISO 8601 Date String representing the original air date or published date',
						type: 'string',
					},
					auth0_id: {
						description: "User's Auth0 value",
						type: ['string', 'null'],
					},
					closed_captions: {
						description:
							'Set to language selected in the Closed Caption dropdown',
						type: ['string', 'null'],
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					full_episode: {
						description:
							'true if content is a full episode and false otherwise',
						type: ['boolean', 'null'],
					},
					full_screen: {
						description:
							'true if playback is currently in full screen mode and false otherwise',
						type: ['boolean', 'null'],
					},
					keywords: {
						description:
							'An array of arbitrary keywords or tags that describe or categorize the video content',
						items: {
							type: ['string', 'null'],
						},
						type: 'array',
					},
					original_content: {
						description:
							"true if content is Sotheby's original and false if licensed from 3rd party",
						type: ['boolean', 'null'],
					},
					playlist_display_name: {
						description: 'Playlist display name',
						type: ['string', 'null'],
					},
					playlist_internal_name: {
						description: 'Internal name of playlist if applicable',
						type: ['string', 'null'],
					},
					position: {
						description:
							'The position (integer) of the thing being interacted with',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					preferred_member_flag: {
						description: 'If user is Preferred',
						type: ['boolean', 'null'],
					},
					quality: {
						description: 'The quality of the video',
						type: ['string', 'null'],
					},
					sound: {
						description:
							'The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume',
						multipleOf: 1,
						type: 'number',
					},
					total_length: {
						description:
							'The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					video_player: {
						description: 'Video player',
						type: ['string', 'null'],
					},
					video_title: {
						description: 'The title of the video',
						type: ['string', 'null'],
					},
				},
				required: ['airdate', 'sound'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Video Playback Completed',
		type: 'object',
	}
	const message = {
		event: 'Video Playback Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User presses play on a viedo conten
 *
 * @param {VideoPlaybackStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function videoPlaybackStarted(
	props: VideoPlaybackStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-06/schema#',
		description: 'User presses play on a viedo conten',
		properties: {
			context: {},
			properties: {
				additionalProperties: false,
				properties: {
					airdate: {
						description:
							'An ISO 8601 Date String representing the original air date or published date',
						type: ['string', 'null'],
					},
					auth0_id: {
						description: "User's Auth0 value",
						type: ['string', 'null'],
					},
					closed_captions: {
						description:
							'Set to language selected in the Closed Caption dropdown',
						type: ['string', 'null'],
					},
					customer_id: {
						description: 'Client system party ID',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					department_category: {
						description: 'Name of department(s) to which the event is related',
						type: ['string', 'null'],
					},
					full_screen: {
						description:
							'true if playback is currently in full screen mode and false otherwise',
						type: ['boolean', 'null'],
					},
					keywords: {
						description:
							'An array of arbitrary keywords or tags that describe or categorize the video content',
						items: {
							type: ['string', 'null'],
						},
						type: 'array',
					},
					original_content: {
						description:
							"true if content is Sotheby's original and false if licensed from 3rd party",
						type: ['boolean', 'null'],
					},
					playlist_display_name: {
						description: 'Playlist display name',
						type: ['string', 'null'],
					},
					playlist_internal_name: {
						description: 'Internal name of playlist if applicable',
						type: ['string', 'null'],
					},
					position: {
						description:
							'The position (integer) of the thing being interacted with',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					preferred_member_flag: {
						description: 'If user is Preferred',
						type: ['boolean', 'null'],
					},
					quality: {
						description: 'The quality of the video',
						type: ['string', 'null'],
					},
					sound: {
						description:
							'The sound level of the playback represented in a 0 to 100 scale where 0 is muted and 100 is full volume',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					total_length: {
						description:
							'The total duration of the playback in seconds. This should include the duration of all your content and ad included in this playback session. For livestream playback',
						multipleOf: 1,
						type: ['number', 'null'],
					},
					video_player: {
						description: 'Video player',
						type: ['string', 'null'],
					},
					video_title: {
						description: 'The title of the video',
						type: ['string', 'null'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Video Playback Started',
		type: 'object',
	}
	const message = {
		event: 'Video Playback Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Video Playback Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * User completes auction registration
	 *
	 * @param {AuctionRegistrationCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	auctionRegistrationCompleted,
	/**
	 * User completes the bid flow and the server sends a response on the result of the bid (bid successful, bid too low)
	 *
	 * @param {BidPlaced} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bidPlaced,
	/**
	 * User clicks the Bid button to start the bid flow
	 *
	 * @param {BidStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bidStarted,
	/**
	 * User sees an error in the cart or checkout
	 *
	 * @param {CheckoutError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutError,
	/**
	 * User starts a checkout of a cart by clicking a ''Checkout Now" button when the user is logged in. Event triggers: 1. Sent when a user succesfuly starts a checkout by pressing the checkout button while logged in. Trigger on all sources. View in Avo: https://www.avo.app/schemas/sqtpEbdMfEfjjyqszKnR/branches/bsvCEtz6s/events/R2qKtMiwey/trigger/Hag3oBfgz
	 *
	 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStarted,
	/**
	 * User completed a checkout step
	 *
	 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * Sent when an error occurs, on any page or action
	 *
	 * @param {ErrorOccurred} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	errorOccurred,
	/**
	 * User clicks non-sothebys.com domain link to exit the website
	 *
	 * @param {ExitLinkClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exitLinkClicked,
	/**
	 * Fires a 'Item Followed' track call.
	 *
	 * @param {ItemFollowed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemFollowed,
	/**
	 * Sent when a user saves an item, e.g. clicks the Save button on a lot
	 *
	 * @param {ItemSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemSaved,
	/**
	 * Fires a 'Item Unfollowed' track call.
	 *
	 * @param {ItemUnfollowed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemUnfollowed,
	/**
	 * Sent when a user unsaves an item , e.g. clicks the Unsave button on a lot
	 *
	 * @param {ItemUnsaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemUnsaved,
	/**
	 * A listing on the metaverse marketplace is completed successfully
	 *
	 * @param {ListItemCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	listItemCompleted,
	/**
	 * A listing on the metaverse marketplace is completed removed
	 *
	 * @param {ListItemRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	listItemRemoved,
	/**
	 * A listing on the metaverse marketplace is started by clicking a Create Listing button
	 *
	 * @param {ListItemStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	listItemStarted,
	/**
	 * A step towards creating a listing on the metaverse marketplace is completed
	 *
	 * @param {ListItemStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	listItemStepCompleted,
	/**
	 * An event that only triggers a .page call in Segment
	 *
	 * @param {LogPageView} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	logPageView,
	/**
	 * User clicks the ''Make an Offer" button.
	 *
	 * @param {MakeAnOfferClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	makeAnOfferClicked,
	/**
	 * Offer canceled by... buyer/seller?
	 *
	 * @param {OfferCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerCanceled,
	/**
	 * User submitted an offer. Sent from the confirmation page
	 *
	 * @param {OfferCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerCompleted,
	/**
	 * Offer rejected by user
	 *
	 * @param {OfferRejected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerRejected,
	/**
	 * Fires a 'Offer Started' track call.
	 *
	 * @param {OfferStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerStarted,
	/**
	 * Fires a 'Offer Step Completed' track call.
	 *
	 * @param {OfferStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerStepCompleted,
	/**
	 * User completed the order
	 *
	 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * User starts simple registration
	 *
	 * @param {SimpleRegistrationStart} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	simpleRegistrationStart,
	/**
	 * Fires a 'Swap Completed' track call.
	 *
	 * @param {SwapCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	swapCompleted,
	/**
	 * User consents to terms and conditions that are presented. This is used for any type of terms, e.g. auction terms, email list terms and more
	 *
	 * @param {TermsAccepted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	termsAccepted,
	/**
	 * Transaction Rejected by seller of buyer, e.g. in a secondary makerketplace
	 *
	 * @param {TransactionRejected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transactionRejected,
	/**
	 * Video playback reaches 100%
	 *
	 * @param {VideoPlaybackCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackCompleted,
	/**
	 * User presses play on a viedo conten
	 *
	 * @param {VideoPlaybackStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	videoPlaybackStarted,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string') {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/sothebys/protocols/tracking-plans/rs_2HGl9L9267xLNY5Ctkn0Tz5Grmc`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
