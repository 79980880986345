import { ethers, utils, BigNumber } from "ethers";
import ERC721CreatorImplementation from "../../data/abi/ERC721CreatorImplementation.json";
import ERC115 from "../../data/abi/ERC115.json";
import { GetGasLimit, getNetwork } from "./getBalance.util";
import marketPlace from "../../data/abi/BuynowMarketplace.json";
import { roundUp, truncateDecimal } from './numberFormet.util';
import { getProviderType, getAccountProvider } from "@utils";
import moment from "moment";

interface isWethApprovalProps {
  price: number;
  abiFile: any;
  taxPercentage: number | undefined;
  isWethAprrovalLoaderStart: () => void;
  isWethAprrovalLoaderStop: () => void;
  currenyContractAddress: string;
  marketPlaceContractAddress: string;
  status?: boolean;
}

interface allowanceCheckProps {
  price: number;
  abiFile: any;
  currencyContractAddress: string;
  marketPlaceContractAddress: string;
  buyerAddress: string;
}

interface allowanceCheckProps {
  price: number;
  abiFile: any;
  currencyContractAddress: string;
  marketPlaceContractAddress: string;
  buyerAddress: string;
}

interface unlockWethProps {
  price: number;
  abiFile: any;
  taxPercentage: number | undefined;
  unLockWethLoaderStart: () => void;
  unLockWethLoaderStop: () => void;
  unLockWethError: () => void;
  unLockWaitForTransaction: (hash: string) => void;
  unLockWethRejectError: () => void;
  currenyContractAddress: string;
  marketPlaceContractAddress: string;
}

interface buyNowProps {
  tokenid: string;
  price: number;
  taxPercentage: number | undefined;
  owner: string;
  contractAddress: string;
  proofOfApproval: string;
  random: string;
  quantity: number;
  buyNowLoaderStart: () => void;
  buyNowLoaderStop: () => void;
  buyNowSuccess: (e: string) => void;
  rejectBuyNow: () => void;
  buyNowWaitForTransaction: (hash: string) => void;
  currenyContractAddress: string;
  selectedAddress: string;
  marketPlaceContractAddress: string;
  chainId: number;
  errorBuyNow: (e?: string | undefined) => void;
}

interface swapAmountProps {
  price: number;
  abiFile: any;
  swapLoaderStart: () => void;
  swapWaitForTransaction: (hash: string) => void;
  swapLoaderStop: () => void;
  rejectSwap: () => void;
  errorSwap: () => void;
  wethAddress: string;
}

export const CheckApproval = async (
  marketPlaceContractAddress: string,
  nftContractAddress: string,
  contractName: string,
): Promise<any> => {

  const contractAddr = marketPlaceContractAddress;
  try {
    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    if (contractName === "ERC721 -Test MarketPlace Tokens") {
      const erc21contract = new ethers.Contract(
        nftContractAddress,
        ERC721CreatorImplementation.abi,
        signer
      );
      const approval = await erc21contract.isApprovedForAll(
        signerAddress,
        contractAddr
      );
      return approval;
    } else {
      const erc115contract = new ethers.Contract(
        nftContractAddress,
        ERC115.abi,
        signer
      );
      const approval = await erc115contract.isApprovedForAll(
        signerAddress,
        contractAddr
      );

      return approval;
    }
  } catch (error: any) {
    console.log(error);
  }
};

export const waitForApproval = async(transaction: any): Promise<true | undefined> => {
  await transaction.wait();
  if (transaction.hash) {
    return true;
  }
}

export const ApproveAll = async (
  marketPlaceContractAddress: string,
  nftContractAddress: string,
  contractName: string,
  onApproveAllError?: (error: any) => void
): Promise<any> => {
  
  const contractAddr = marketPlaceContractAddress;
  try {
    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const signer = provider.getSigner();

    if (contractName === "ERC721 -Test MarketPlace Tokens") {
      const erc721contract = new ethers.Contract(
        nftContractAddress,
        ERC721CreatorImplementation.abi,
        signer
      );
      const transaction = await erc721contract.setApprovalForAll(
        contractAddr,
        true
      );
      const data = await provider.waitForTransaction(transaction.hash, 1);
      if (data) {
        return transaction
      } else {
        return null;
      }
    } else {
      const erc115contract = new ethers.Contract(
        nftContractAddress,
        ERC115.abi,
        signer
      );
      const transaction = await erc115contract.setApprovalForAll(
        contractAddr,
        true
      );
      const data = await provider.waitForTransaction(transaction.hash, 1);
      if (data) {
        return transaction
      } else {
        return null;
      }
    }
  } catch (error: any) {
    onApproveAllError?.(error);
    if (
      error.message ==
      "MetaMask Tx Signature: User denied transaction signature."
    ) {
      return false;
    }
  }
};

interface IacceptOffer {
  tokenid: string,
  price: number,
  owner: string,
  contractAddress: string,
  proofOfApproval: string,
  random: string,
  quantity: number,
  tax: number,
  ExpiryDate: string,
  onHandleLoader?: (hash: string) => void;
  marketPlaceContractAddress: string;
  chainId: number;
}   
export const acceptOffer = async (props: IacceptOffer): Promise<any> => {
  const taxPercentage = truncateDecimal(Number(props.tax), 4);
  // const gasLimit = GetGasLimit(props.chainId);
  const providerType = getProviderType();
  const provider = await getAccountProvider(providerType);
  const { chainId } = await provider.getNetwork();
  const Weth_Address = getNetwork(chainId) ?? '';
  const signer = provider.getSigner();
  const signerAddress = await signer.getAddress();
  const contract = new ethers.Contract(
    props.marketPlaceContractAddress,
    marketPlace.abi,
    signer
  );
  const transaction = await contract.sell(
    [
      props.random,
      props.tokenid,
      props.contractAddress,
      props.quantity,
      signerAddress, // OWNER ADDRESS
      utils.parseEther(String(props.price)),
      Weth_Address,
      utils.parseEther(String(taxPercentage)),
      ethers.constants.AddressZero,
      0,
      0,
      props.owner, // BUYER ADDRESS
    ],
    props.proofOfApproval,
    moment(props.ExpiryDate).unix(), //Expire Date timestramp
    Weth_Address,
    // {gasLimit: gasLimit}
  );
  props?.onHandleLoader?.(transaction.hash);
  const data = await provider.waitForTransaction(transaction.hash, 1);
  if (data?.status === 0) {
    return "error";
  } else {
    return transaction.hash;
  }
}

export async function isApproval(props: isWethApprovalProps): Promise<boolean | undefined | string> {
  
  const tax = Number(props.taxPercentage);
  const ReservePrice = Number((props.price + tax));
  const unLockPrice = +truncateDecimal(ReservePrice, 4);
  const providerType = getProviderType();
  const provider = await getAccountProvider(providerType);
  const signer = provider.getSigner();
  const signerAddress = await signer.getAddress();
  const currenyContractAddress = props.currenyContractAddress;

  const wethcontract = new ethers.Contract(currenyContractAddress, props.abiFile, signer);
  if(props.marketPlaceContractAddress !== undefined){
    const balanceOf = await wethcontract.allowance(
      signerAddress,
      props.marketPlaceContractAddress
    );
    let value = Number(balanceOf) / 1000000000000000000;
    if (value >= unLockPrice) {
      props.isWethAprrovalLoaderStart();
      return props?.status ? 'success' : true;
    } else {
      value = ReservePrice - value;
      props.isWethAprrovalLoaderStop();
      return props?.status ? 'error' : false;
    }
  } else{
    return props?.status ? 'error' : false
  }
}

export async function unlockPrice(props: unlockWethProps): Promise<boolean | undefined> {
  
  try {
    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const signer = provider.getSigner();
    const unlockFinalPrice = utils.parseEther("999999999");
    const currenyContractAddress = props.currenyContractAddress;
    const wethcontract = new ethers.Contract(
      currenyContractAddress,
      props.abiFile,
      signer
    );
    props.unLockWethLoaderStart();
    const transaction = await wethcontract.approve(
      props.marketPlaceContractAddress,
      unlockFinalPrice
    );
    props.unLockWaitForTransaction(transaction.hash);
    const data = await provider.waitForTransaction(transaction.hash, 1);
    if (data) {
      props.unLockWethLoaderStop();
      return true;
    } else {
      props.unLockWethError();
      return false;
    }
  } catch (error: any) {
    console.log("error", error);
    if (error.message?.includes('user rejected transaction') || error.message?.includes('User denied transaction signature')) {
      props.unLockWethRejectError();
    } else {
      props.unLockWethError();
    }
  }
}

export async function buyNftWithWethOrWmatic(props: buyNowProps): Promise<boolean | undefined> {
  try {
    const currenyContractAddress = props.currenyContractAddress;
    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    const marketPlaceContractAddress = props.marketPlaceContractAddress;
    // const gasLimit = GetGasLimit(props.chainId);
    const contract = new ethers.Contract(
      marketPlaceContractAddress,
      marketPlace.abi,
      signer
    );
    const quantity = 1;
    const zero = 0;
    const price = props.price.toString();
    // const gasprice = await signer.getGasPrice();
    const transaction = await contract.buy(
      [
        props.random,
        BigNumber.from(props.tokenid),
        props.contractAddress,
        quantity,
        props.owner,
        utils.parseEther(price),
        currenyContractAddress, // Listing Address
        utils.parseEther(String(props.taxPercentage)),
        ethers.constants.AddressZero,
        zero,
        zero,
        signerAddress,
      ],
      props.proofOfApproval,
      props.selectedAddress,
      // {gasLimit: gasLimit}
    );
    props.buyNowLoaderStart();
    props.buyNowWaitForTransaction(transaction.hash);
    const data = await provider.waitForTransaction(transaction.hash, 1);
    if (data?.status === 0) {
      props.errorBuyNow();
    } else {
      props.buyNowLoaderStop();
      props.buyNowSuccess(transaction.hash);
    }
    return true;
  } catch (error: any) {
    console.log("error", error)
    if (error.message?.includes('user rejected transaction') || error.message?.includes('User denied transaction signature')) {
      props.rejectBuyNow();
    } else {
      props.errorBuyNow(error?.message);
    }
  }
}

export async function buyNftWithNativeCurrency(props: buyNowProps): Promise<boolean | undefined> {
  try {
    const currenyContractAddress = props.currenyContractAddress;
    const tax = truncateDecimal(Number(props.taxPercentage), 4);
    const totalAmount = truncateDecimal(Number(props.price) + Number(tax), 4);
    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    const contractAddress = props.marketPlaceContractAddress;
    // const gasLimit = GetGasLimit(props.chainId);
    const contract = new ethers.Contract(
      contractAddress,
      marketPlace.abi,
      signer
      );
    const quantity = 1;
    const zero = 0;
    const price = props.price.toString();
    // const gasprice = await signer.getGasPrice();
    const transaction = await contract.buy(
      [
        props.random,
        BigNumber.from(props.tokenid),
        props.contractAddress,
        quantity,
        props.owner,
        utils.parseEther(price),
        currenyContractAddress, // Listing Address
        utils.parseEther(String(tax)),
        ethers.constants.AddressZero,
        zero,
        zero,
        signerAddress,
      ],
      props.proofOfApproval,
      props.selectedAddress,
      {
        value: utils.parseEther(String(totalAmount)),
      }

    );
    props.buyNowLoaderStart();
    props.buyNowWaitForTransaction(transaction.hash);
    const data = await provider.waitForTransaction(transaction.hash, 1);
    if (data?.status === 0) {
      props.errorBuyNow();
    } else {
      props.buyNowLoaderStop();
      props.buyNowSuccess(transaction.hash);
    }
    return true;
  } catch (error: any) {
    console.log("error", error);
    if (error.message?.includes('user rejected transaction') || error.message?.includes('User denied transaction signature')) {
      props.rejectBuyNow();
    } else {
      props.errorBuyNow(error?.message);
    }
  }
}

export async function SwapPrice(props: swapAmountProps): Promise<true | undefined> {
  
  try {
    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const signer = provider.getSigner();
    const price = roundUp(props.price, 4);
    const Weth_Address = props.wethAddress;
    const wethcontract = new ethers.Contract(
      Weth_Address,
      props.abiFile,
      signer
    );
    props.swapLoaderStart();
    const transaction = await wethcontract.deposit({
      value: utils.parseEther(String(price)),
    });
    props.swapWaitForTransaction(transaction.hash);
    const data = await provider.waitForTransaction(transaction.hash, 1);
    if (data) {
      props.swapLoaderStop();
    } else {
      props.swapLoaderStop();
    }
    return true;
  } catch (error: any) {
    if (error.message?.includes('user rejected transaction') || error.message?.includes('User denied transaction signature')) {
      props.rejectSwap();
    } else {
      props.errorSwap();
    }
  }
}

export async function buyerAllowanceCheck({abiFile, buyerAddress, currencyContractAddress, marketPlaceContractAddress, price}: allowanceCheckProps): Promise<{
  status: boolean;
  message: string;
}> {
  
  const unLockPrice = +truncateDecimal(+price, 4);
  const providerType = getProviderType();
  const provider = await getAccountProvider(providerType);
  const signer = provider.getSigner();

  const wethcontract = new ethers.Contract(currencyContractAddress, abiFile, signer);
  if(marketPlaceContractAddress !== undefined){
    const balanceOf = await wethcontract.allowance(
      buyerAddress,
      marketPlaceContractAddress
    );

    let value = Number(balanceOf) / 1000000000000000000;
    if (value >= unLockPrice) {
      return {
        status: true,
        message: "success"
      };
    } else {
      value = +price - value;
      return {
        status: false,
        message: `The buyer's approval or balance of ${price} WETH is insufficient to complete this purchase. Please try again or consider rejecting this offer.`
      };
    }
  } else{
    return {
      status: false,
      message: `The buyer's approval or balance of ${price} WETH is insufficient to complete this purchase. Please try again or consider rejecting this offer.`
    };
  }
}
