import { config } from "@constants";
import { ConsentManager } from "@segment/consent-manager";
import Link from "next/link";
import { memo, ReactElement } from "react";

export const ConsentDialog = memo(
  ({ segmentKey }: { segmentKey: string }): ReactElement => {
    const bannerContent = (
      <span>
        By using this site, you agree to our updated{" "}
        <Link href="/privacy-policy">Privacy Policy</Link> and{" "}
        <Link href={config.SOTHEBYS_DOMAIN + "/en/cp.html"} passHref>
          <a
            href={config.SOTHEBYS_DOMAIN + "/en/cp.html"}
            target="_blank"
            rel="noreferrer">
            Cookie Policy
          </a>
        </Link>
      </span>
    );
    const bannerSubContent = "You can change your preferences at any time.";
    const preferencesDialogTitle = "Website Data Collection Preferences";
    const preferencesDialogContent =
      "We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.";
    const cancelDialogTitle = "Are you sure you want to cancel?";
    const cancelDialogContent =
      "Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.";

    return (
      <ConsentManager
        writeKey={segmentKey}
        shouldRequireConsent={() => false}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        bannerActionsBlock={true}
        implyConsentOnInteraction={true}
      />
    );
  }
);
