import { useRequest } from "ahooks";
import axios from "axios";
// import Lottie from "lottie-react";
import Image from "next/image";
import React from "react";
import styled from "styled-components";

export const Loader = styled(
  ({
    width = 100,
    message,
    ...props
  }: {
    width?: number;
    contained?: boolean;
    message?: string;
  }) => {
    const { loading } = useRequest(
      () => axios("/animations/sothebys.json", { responseType: "json" }),
      {
        cacheKey: "sothebys-logo",
        cacheTime: -1,
        staleTime: -1,
      }
    );
    return loading ? null : (
      <div {...props}>
        {/* <Lottie animationData={data?.data} style={{ width }} autoPlay loop /> */}
        <Image unoptimized={true} src={"/images/loader_sothebys.gif"} alt={"loader"} width={width} height={width} />
        {message && <span>{message}</span>}
      </div>
    );
  }
)`
  width: 100%;
  margin: 0;
  position: ${({ contained }) => (contained ? "relative" : "absolute")};
  top: ${({ contained }) => (contained ? "auto" : "50%")};
  left: ${({ contained }) => (contained ? "auto" : "50%")};
  ${({ contained }) => !contained && "transform: translate(-50%, -50%);"}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    color: rgba(166, 166, 166, 1);
  }
`;
