import { ethers } from "ethers";
import WETH from "../../data/abi/WETH.json";
import WMATIC from "../../data/abi/MATIC.json";
import web3 from "web3";
import { LOCAL_STORAGE_STATE } from "./clearLocalStorage";
import { isEmpty, truncateDecimal } from "./numberFormet.util";

export function getNetwork(id: string | number): string | undefined {
  if (id === "") {
    return undefined
  }
  if (+id === 80001) {
    return process.env.NEXT_PUBLIC_WETH_CONTRACT_ADDRESS_MUMBAI_TESTNET;
  } else if (+id === 1) {
    return process.env.NEXT_PUBLIC_WETH_CONTRACT_ADDRESS_MAINNET;
  } else if (+id === 4) {
    return process.env.NEXT_PUBLIC_WETH_CONTRACT_ADDRESS_RINKEBY;
  } else if (+id === 3) {
    return process.env.NEXT_PUBLIC_WETH_CONTRACT_ADDRESS_ROPSTEN;
  } else if (+id === 5) {
    return process.env.NEXT_PUBLIC_WETH_CONTRACT_ADDRESS_GOERLI;
  } else if (+id === 42) {
    return process.env.NEXT_PUBLIC_WETH_CONTRACT_ADDRESS_KOVAN;
  } else if (+id === 137) {
    return process.env.NEXT_PUBLIC_WETH_CONTRACT_ADDRESS_POLYGON_MAINNET;
  } else if (+id === 11155111) {
    return process.env.NEXT_PUBLIC_WETH_CONTRACT_ADDRESS_SEPOLIA;
  }
}
//Weth balance
export async function wethbalance(): Promise<string | undefined> {
  try {
    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    const { chainId } = await provider.getNetwork();
    const Weth_Address = getNetwork(chainId);
    const wethcontract = new ethers.Contract(
      String(Weth_Address),
      WETH.abi,
      signer
    );
    const balanceOf = await wethcontract.balanceOf(signerAddress);
    return truncateDecimal((balanceOf / 1e18), 4);
  } catch (e) {
    console.log(e)
  }
}
//eth and MATIC balance
export async function ethBalance(): Promise<string> {
  const providerType = getProviderType();
  const provider = await getAccountProvider(providerType);
  const accounts = await provider.listAccounts();
  const balanceEth = await provider.getBalance(accounts[0]);
  const balance = web3.utils.fromWei(String(balanceEth), "ether");
  return truncateDecimal(balance, 4);
}
//Wmatic balance
export async function wmaticBalance(): Promise<string | undefined> {
  const providerType = getProviderType();
  const provider = await getAccountProvider(providerType);
  const signer = provider.getSigner();
  const signerAddress = await signer.getAddress();
  const { chainId } = await provider.getNetwork();
  const Weth_Address = getNetwork(chainId);
  const wethcontract = new ethers.Contract(
    String(Weth_Address),
    WMATIC.abi,
    signer
  );
  const balanceOf = await wethcontract.balanceOf(signerAddress);
  return truncateDecimal((balanceOf / 1e18), 4);
}

export function CheckNetworkTokens(
  chainId: number | undefined,
  contractAddress: string | undefined,
  id: string | undefined
): string {
  if (chainId == 80001) {
    const url =
      'https://mumbai.polygonscan.com/token/' + contractAddress + '?a=' + id
    return url
  } else if (chainId == 4) {
    const url =
      'https://rinkeby.etherscan.io/token/' + contractAddress + '?a=' + id
    return url
  } else if (chainId == 1) {
    const url = 'https://etherscan.io/token/' + contractAddress + '?a=' + id
    return url
  } else if (chainId == 3) {
    const url =
      'https://ropsten.etherscan.io/token/' + contractAddress + '?a=' + id
    return url
  } else if (chainId == 5) {
    const url =
      'https://goerli.etherscan.io/token/' + contractAddress + '?a=' + id
    return url
  } else if (chainId == 42) {
    const url =
      'https://kovan.etherscan.io/token/' + contractAddress + '?a=' + id
    return url
  } else if (chainId == 137) {
    const url = 'https://polygonscan.com/token/' + contractAddress + '?a=' + id
    return url
  } else if(chainId == 11155111) {
    const url = "https://sepolia.etherscan.io/token" + contractAddress + "?a=" + id;
    return url;
  }  else {
    const url = 'https://etherscan.io/token/' + contractAddress + '?a=' + id
    return url
  }
}

export async function buyerBalance(address: string): Promise<string | undefined> {
  const providerType = getProviderType();
  const provider = await getAccountProvider(providerType);
  const signer = provider.getSigner();
  const { chainId } = await provider.getNetwork();
  const Weth_Address = getNetwork(chainId);
  const file = (chainId == 80001 || chainId == 137) ? WMATIC.abi : WETH.abi;
  const wethcontract = new ethers.Contract(
    String(Weth_Address),
    file,
    signer
  );
  const balanceOf = await wethcontract.balanceOf(address);
  return truncateDecimal((balanceOf / 1e18), 4);
}


export const getAccountProvider = async (type = "META_MASK"): Promise<ethers.providers.Web3Provider | any> => {
  if (["META_MASK"].includes(type)) {
    return new ethers.providers.Web3Provider(window.ethereum);
  }
}


export const WalletProvider = (): any => {
  return undefined
}

export const getProviderType = (): string => {
  if (!isEmpty(localStorage.getItem(LOCAL_STORAGE_STATE.secondary_wallet.providerType)))
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE.secondary_wallet.providerType) ?? "META_MASK");
  else
    return "META_MASK";
}

export const OnSwitchNetwork = async (chain: string): Promise<any> => {
  try {
    if (getProviderType() === "META_MASK") {
      return await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chain}` }],
      });
    } else {
      const web3 = WalletProvider();
      await web3.enable();
      web3.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chain}` }],
      });
    }
  } catch (e: any) {
    return e
  }

}

export const getAbiFile = (currency: string): any => {
  if (currency === "WETH") {
    return WETH.abi;
  } else {
    return WMATIC.abi;
  }
};

export const getBalanceonChainID = async (chainId: number): Promise<any> => {
  const balance: any = {
    Wbalance: "",
    balance: "",
    currency: "",
  };
  if (chainId == 80001 || chainId == 137) {
    balance["Wbalance"] = await wmaticBalance();
    balance["balance"] = await ethBalance();
    balance["currency"] = "MATIC";
  } else {
    balance["Wbalance"] = await wethbalance();
    balance["balance"] = await ethBalance();
    balance["currency"] = "WETH";
  }
  return balance;
};

export const GetGasLimit = (chainId: number): number => {
  if (chainId == 80001) {
    return 1000000
  } else if (chainId == 4) {
    return 100000
  } else if (chainId == 1) {
    return 30000000
  } else if (chainId == 3) {
    return 100000
  } else if (chainId == 5) {
    return 210000
  } else if (chainId == 42) {
    return 100000
  } else if (chainId == 137) {
    return 500000
  } else if (chainId == 11155111) {
    return 210000;
  } else {
    return 100000
  }
}
