import { EViewSize } from "@enums";
import { IMojitoCollectionItem } from "@interfaces";
import { Box, Grid } from "@material-ui/core";
import { formatCurrency, premiumCalculator } from "@utils";
import React, { useContext } from "react";
import styled from "styled-components";
import { CreatedBy } from "../../createdBy";
import { FavoriteButton } from "../../saveToFavoriteButton";
import { CollectionItemContext } from "../state/auctionItemContext";
import { CollectedBy } from "./collectedBy";
import { Estimate, ItemTitle } from "./general";
import { LotNotices } from "./LotNotices";
import { NextBid } from "./nextBid";
import { PlaceBidButton } from "./placeBidButton";

const SoldFor = styled(
  ({ mojitoItemDetails, ...props }: { mojitoItemDetails: any }) => {
    if (
      !(
        !!mojitoItemDetails?.bids?.length &&
        mojitoItemDetails?.saleView?.isPostSale &&
        mojitoItemDetails?.feeStructure
      )
    )
      return null;

    const winAmount = mojitoItemDetails?.bids?.[0]?.amount;
    const feeStructure = mojitoItemDetails?.feeStructure;

    const fee = premiumCalculator(winAmount, feeStructure);
    const soldPrice =
      winAmount + fee.buyersPremiumRate + fee.overheadPremiumRate;

    return <div {...props}>sold for: {formatCurrency(soldPrice)}</div>;
  }
)`
  margin-top: 10px;
  font-family: "BentonSans";
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  text-transform: uppercase;
`;

const ItemInfoGrid = styled((props) => <Grid {...props} />)`
  && {
    padding-right: 10px;
  }
`;

const CollectedByBox = styled((props) => <Box {...props} />)`
  && {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: ${EViewSize.mobile}) {
      justify-content: flex-start;
      padding-top: 10px;
    }
  }
`;

const BidActionGrid = styled((props) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const PlaceBidBox = styled(
  ({ mojitoItem, ...props }: { mojitoItem?: IMojitoCollectionItem<any> }) => {
    return mojitoItem?.details?.saleView?.isDuringSale ||
      mojitoItem?.details?.saleView?.isPostSale ? (
      <div {...props}>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          <Grid item xs={6}>
            <PlaceBidButton mojitoItem={mojitoItem} />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FavoriteButton collectionItemId={mojitoItem.id} />
          </Grid>
        </Grid>
        <NextBid mojitoItemId={mojitoItem.id} />
      </div>
    ) : null;
  }
)`
  position: relative;

  @media screen and (max-width: ${EViewSize.mobile}) {
    margin-top: 15px;
  }
`;

export const BidCardHeader = styled(
  ({ mojitoItemDetails, ...props }: { mojitoItemDetails: any }) => {
    const { item } = useContext(CollectionItemContext);
    const lotData = item.contentfulData;

    return (
      <div {...props}>
        <Grid container spacing={0}>
          <ItemInfoGrid item xs={12} sm={6}>
            <ItemTitle>{lotData.title}</ItemTitle>
            <CreatedBy
              authorName={lotData.author?.name}
              authorSlug={lotData.author?.slug}
              createdDate={lotData.createdAt}
            />
            {!mojitoItemDetails?.saleView?.isPostSale && (
              <Estimate lotData={lotData} />
            )}
            <SoldFor mojitoItemDetails={mojitoItemDetails} />
            <LotNotices mojitoItemDetails={mojitoItemDetails} />
          </ItemInfoGrid>
          <BidActionGrid item xs={12} sm={6}>
            {lotData.collector && (
              <CollectedByBox>
                <CollectedBy
                  collectorName={lotData.collector?.name}
                  collectorSlug={lotData.collector?.slug}
                />
              </CollectedByBox>
            )}
            <PlaceBidBox mojitoItem={item} />
          </BidActionGrid>
        </Grid>
      </div>
    );
  }
)`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;

  @media screen and (max-width: ${EViewSize.tablet}) {
    margin-bottom: 5px;
  }
`;
