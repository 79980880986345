import { RequestDocument, Variables } from "graphql-request/dist/types";
import { GraphQLClient } from "graphql-request";
import { QueryClient } from "react-query";
import isBrowser from "./isBrowser";
import { secondaryEvents } from "../hooks/secondaryTrackingEvent";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: typeof window === "undefined" ? 0 : 180000, // 3min
      cacheTime: Infinity, // disable garbage collection
    },
  },
});

interface IGqlRequestParams {
  query: RequestDocument;
  variables?: Variables;
  normalizerFn?: (data: any, variables?: Variables) => any;
  gqlClient: GraphQLClient;
  pathName?: string | undefined;
}

export async function gqlRequest<T>({
  query,
  variables,
  normalizerFn,
  gqlClient,
  pathName
}: IGqlRequestParams): Promise<any> {
  const {errorEvent} = secondaryEvents();
  return await gqlClient
    .request<T>(query, variables)
    .catch((e) => {
      errorEvent({
        error_code: e.response.status,
        error_msg: e.response.errors[0].message,
        page_name: pathName ?? "",
        path: e.response.errors[0].path[0]
      });
      if (
        isBrowser &&
        e.response.status >= 500 &&
        location.pathname != "/500"
      ) {
        document.location.href = "/500";
      } else {
        throw e.response.errors[0];
      }
    })
    .then((data) => normalizerFn?.(data, variables));
}
