interface IFeeStructure {
  from: number;
  to: number;
  rate: number;
}

export const premiumCalculator = (
  amount: number,
  feeStructure: {
    buyersPremiumRate: IFeeStructure[];
    overheadPremiumRate: IFeeStructure[];
  }
): {
  buyersPremiumRate: number;
  overheadPremiumRate: number;
} => {
  if (!feeStructure) return { buyersPremiumRate: 0, overheadPremiumRate: 0 };

  const fee = { buyersPremiumRate: 0, overheadPremiumRate: 0 };

  Object.assign(
    fee,
    Object.keys(fee).reduce<any>(
      (acc, key) =>
        Object.assign(acc, {
          // @ts-ignore
          [key]: [...feeStructure[key]]
            .reverse()
            .reduce(
              (_fee: number, e: any) =>
                (_fee +=
                  amount >= e.from
                    ? ((e.to ? Math.min(amount, e.to) : amount) - e.from) *
                      e.rate
                    : 0),
              0
            ),
        }),
      {}
    )
  );

  return fee;
};
